import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import share1 from "../Image/share1.png";
import share2 from "../Image/share2.png";
import trending from "../Image/trending.png";
import { SlCalender } from "react-icons/si";
import calender from "../Image/calender.png";
import download from "../Image/download.png";
import { Dropdown } from "react-bootstrap";

function Aum_Chart({ api }) {
  const [chartData, setChartData] = useState([]);
  const [h_24, seth_24s] = useState(1);
  const [var_price, setvar_prices] = useState("prices");
  const [price_name, setprice_names] = useState("price");
  console.log(api, h_24);

  const chartOptions = {
    chart: {
      id: "zoomable-chart",
      type: "area",
      height: 350,
      zoom: {
        enabled: true,
      },
      toolbar: {
        autoSelected: "zoom",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return `$${value.toFixed(2)}`;
        },
      },
      opposite: true,
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy",
      },
    },
  };

  // const setMax = () => {
  //   seth_24s);
  // };

  const currentTime = new Date().getTime();

  useEffect(() => {
    console.log(`${api}${h_24}`);
    // Fetch data from API and update chartData state
    fetch(`${api}${h_24}`)
      .then((response) => response.json())
      .then((data) => {
        // Determine which property to access dynamically based on var_price
        const propertyToAccess =
          var_price === "prices" ? "prices" : "market_caps";

        // Process the fetched data and format it for the chart
        const formattedData = data[propertyToAccess].map(
          ([timestamp, value]) => ({
            x: timestamp,
            y: value,
          }),
        );
        setChartData([{ name: price_name, data: formattedData }]);
      })
      .catch((error) => console.log(error));
  }, [h_24, var_price, api]);

  return (
    <div>
      <div className="m-q-d-f flex-wrap  a-i-c  c-p">
        <div className="m-q-m-r-1 m-b-1  b-r-5">
          <div className="d-f a-i-c j-c-s-e pa-5rem bg_g_clr b-r-5">
            <div
              className={`w-7 t-a-c ${
                var_price == "prices" ? "bg_d_b_clr b-r-5 pa-5rem" : ""
              } `}
              onClick={() => setvar_prices("prices")}
            >
              Price
            </div>
            <div
              className={`w-7 t-a-c ${
                var_price == "market_caps" ? "bg_d_b_clr b-r-5 pa-5rem" : ""
              } `}
              onClick={() => setvar_prices("market_caps")}
            >
              Market Cap
            </div>
          </div>
        </div>
        {/*     <div className="m-r-1  pa-5rem  b-r-5">
          <div className="d-f a-i-c pa-5rem bg_g_clr b-r-5">
            <div className="pa-5rem bg_d_b_clr b-r-5 m-r-0_5">
              <img src={share1} alt="" className="w-1" />{" "}
            </div>
            <div className="pa-5rem">
              <img src={share2} alt="" className="w-0_5" />{" "}
            </div>
          </div>
        </div> */}
        {/*  <div className="m-r-1 bg_g_clr pa-5rem b-r-5">
          <img src={trending} alt="" className="w-1" /> TradingView{" "}
        </div>
        <div className="m-r-1 ">
          {" "}
          <Dropdown className="pa-5rem bg_g_clr  b-r-5">
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="b-c-t b-n c-i "
            >
              Compare with
            </Dropdown.Toggle>

            <Dropdown.Menu className="pa-1">
              <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div> */}
        <div className="m-q-m-r-1  m-b-1 ">
          <div className="    b-r-5">
            <div className="d-f a-i-c pa-5rem bg_g_clr b-r-5">
              <div
                className={`   ${
                  h_24 == 1 ? " t-a-c  bg_d_b_clr b-r-5 " : ""
                } m-r-0_5  pa-5rem t-a-c `}
                onClick={() => seth_24s(1)}
              >
                1D
              </div>
              <div
                className={`   ${
                  h_24 == 7 ? " t-a-c  bg_d_b_clr b-r-5 " : ""
                } m-r-0_5  pa-5rem t-a-c `}
                onClick={() => seth_24s(7)}
              >
                7D
              </div>
              <div
                className={`   ${
                  h_24 == 30 ? " t-a-c  bg_d_b_clr b-r-5 " : ""
                } m-r-0_5  pa-5rem t-a-c `}
                onClick={() => seth_24s(30)}
              >
                1M
              </div>
              <div
                className={`   ${
                  h_24 == 365 ? " t-a-c  bg_d_b_clr b-r-5 " : ""
                } m-r-0_5  pa-5rem t-a-c `}
                onClick={() => seth_24s(365)}
              >
                1Y
              </div>
              <div
                className={`   ${
                  h_24 == 1825 ? " t-a-c  bg_d_b_clr b-r-5 " : ""
                } m-r-0_5  pa-5rem t-a-c `}
                onClick={() => seth_24s(1825)}
              >
                5Y
              </div>
              <div
                className={`   ${
                  h_24 == "max" ? " t-a-c  bg_d_b_clr b-r-5 " : ""
                }  pa-5rem t-a-c `}
                onClick={() => seth_24s("max")}
              >
                ALL
              </div>
              {/* <div className="p-x-0_5 b-r_1">
                {" "}
                <img src={calender} alt="" className="w-1" />
              </div> */}
              {/* <div className="p-x-0_5 b-r_1">LOG </div> */}
              {/* <div className="p-x-0_5 ">
                <img src={download} alt="" className="w-0_5" />{" "}
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="b-1 b_clr_gray w-100 p-x-1 p-t-1 p-b-2_5 b-r-5">
        <div className="chart">
          <ReactApexChart
            options={chartOptions}
            series={chartData}
            type="area"
            height={350}
          />
        </div>
      </div>
    </div>
  );
}

export default Aum_Chart;
