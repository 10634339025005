import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./component/pages/Home";

import "./App.scss";
import "./component/scss/Main.scss";
import Page404 from "./component/pages/Page404";
import Crypto_detail from "./component/pages/Crypto_detail";
import Profile from "./component/pages/Profile";
import Create_form from "./component/pages/Create_form";
import Admin from "./component/pages/Admin";
import Admin_Detail from "./component/pages/Admin_Detail";
import List_request from "./component/pages/List_request";
import Request_list_detail from "./component/pages/Request_list_detail";
import Super_admin from "./component/pages/Super_admin";
import Create_login from "./component/pages/Create_login";
import Super_admin_signup from "./component/pages/Super_admin_signup";
import Admin_login from "./component/pages/Admin_login";
import ResetPassword from "./component/pages/ResetPassword";
import List_of_Admin from "./component/pages/List_of_Admin";
import Change_Email from "./component/pages/Change_Email";
import Forgot_password from "./component/pages/Forgot_password";
import Watchlist from "./component/pages/Watchlist";
import Methodology from "./component/pages/Methodology";
import Update_list_detail from "./component/pages/Update_list_detail";
import Create_login_update from "./component/pages/Create_login_update";
import TEst2 from "./component/pages/TEst2";
import ShariaCoin from "./component/pages/ShariaCoin";
import Exchange from "./component/pages/Exchange";
import Terms from "./component/pages/Terms";
import Exchange_detail from "./component/pages/Exchange_detail";
import Disclaimer from "./component/pages/Disclaimer";
import Privacy from "./component/pages/Privacy";
import Sitemap from "./component/pages/Sitemap";
import { useEffect } from "react";
import {
  DissconnectWallet,
  GetChainId,
  getUserAddress,
  MetaMasklogin,
  WalletConnect,
} from "./Web3";

import FearAndGreedWidget from "./component/pages/FearAndGreedWidget_light";
import QueryProvider from "./service/provider";

function App() {
  // const url = "http://localhost:8080";
  const url = "https://api.ramzmarketcap.com";

  useEffect(() => {
    const init = async () => {
      const wallet = window.localStorage.getItem("address");
      if (wallet) {
        await Metamask();
      }
      const id = await GetChainId();
      console.log(id);
      if (Number(id) !== 786) {
        if (window.ethereum) {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x312" }],
          });
        }
      }
    };

    init();
  }, []);

  const WalletC = async () => {
    await WalletConnect();
    const add = await getUserAddress();
    window.location.reload();
    // setUser(add);
  };

  const Metamask = async () => {
    await MetaMasklogin();
    const add = await getUserAddress();
    window.localStorage.setItem("address", JSON.stringify(add));
    // window.location.reload();
    // setUser(add);
  };

  const Dissconnect = async () => {
    await DissconnectWallet();
    // setUser(undefined);
    window.localStorage.removeItem("address");
    // window.location.reload();
  };

  const signup = localStorage.getItem("signup");
  const admin_login = localStorage.getItem("admin_login");

  try {
    window.ethereum.on("accountsChanged", function (accounts) {
      // setUser(accounts[0]);
    });
  } catch (error) {}

  try {
    window.ethereum.on("chainChanged", function (accounts) {
      window.location.reload();
    });
  } catch (error) {}

  return (
    <div>
      <QueryProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  url={url}
                  Dissconnect={Dissconnect}
                  Metamask={Metamask}
                  WalletC={WalletC}
                />
              }
            />

            <Route
              path="/pie_chart"
              element={
                <FearAndGreedWidget
                  url={url}
                  Dissconnect={Dissconnect}
                  Metamask={Metamask}
                  WalletC={WalletC}
                />
              }
            />
            <Route
              path="/sitemap"
              element={
                <Sitemap
                  url={url}
                  Dissconnect={Dissconnect}
                  Metamask={Metamask}
                  WalletC={WalletC}
                />
              }
            />
            <Route
              path="/disclaimer"
              element={
                <Disclaimer
                  url={url}
                  Dissconnect={Dissconnect}
                  Metamask={Metamask}
                  WalletC={WalletC}
                />
              }
            />
            <Route
              path="/privacy"
              element={
                <Privacy
                  url={url}
                  Dissconnect={Dissconnect}
                  Metamask={Metamask}
                  WalletC={WalletC}
                />
              }
            />
            <Route
              path="/exchanges"
              element={
                <Exchange
                  url={url}
                  Dissconnect={Dissconnect}
                  Metamask={Metamask}
                  WalletC={WalletC}
                />
              }
            />
            <Route
              path="/terms"
              element={
                <Terms
                  url={url}
                  Dissconnect={Dissconnect}
                  Metamask={Metamask}
                  WalletC={WalletC}
                />
              }
            />
            <Route
              path="/exchanges/:project_api_named"
              element={
                <Exchange_detail
                  url={url}
                  Dissconnect={Dissconnect}
                  Metamask={Metamask}
                  WalletC={WalletC}
                />
              }
            />
            <Route
              path="/ShariaCoin"
              element={
                <ShariaCoin
                  url={url}
                  Dissconnect={Dissconnect}
                  Metamask={Metamask}
                  WalletC={WalletC}
                />
              }
            />
            <Route
              path="/test2"
              element={
                <TEst2
                  url={url}
                  Dissconnect={Dissconnect}
                  Metamask={Metamask}
                  WalletC={WalletC}
                />
              }
            />
            <Route
              path="/update_list_detail/:name/:id"
              element={
                <Update_list_detail
                  url={url}
                  Dissconnect={Dissconnect}
                  Metamask={Metamask}
                  WalletC={WalletC}
                />
              }
            />
            <Route
              path="/create_login_update/:id"
              element={
                <Create_login_update
                  url={url}
                  Dissconnect={Dissconnect}
                  Metamask={Metamask}
                  WalletC={WalletC}
                />
              }
            />

            {signup ? (
              <>
                <Route
                  path="/create_login"
                  element={
                    <Create_login
                      url={url}
                      Dissconnect={Dissconnect}
                      Metamask={Metamask}
                      WalletC={WalletC}
                    />
                  }
                />
                <Route
                  path="/list_of_admin"
                  element={
                    <List_of_Admin
                      url={url}
                      Dissconnect={Dissconnect}
                      Metamask={Metamask}
                      WalletC={WalletC}
                    />
                  }
                />
              </>
            ) : (
              <>
                {" "}
                <Route
                  path="/super_admin_signup"
                  element={
                    <Super_admin_signup
                      url={url}
                      Dissconnect={Dissconnect}
                      Metamask={Metamask}
                      WalletC={WalletC}
                    />
                  }
                />
                <Route
                  path="/super_admin"
                  element={
                    <Super_admin
                      url={url}
                      Dissconnect={Dissconnect}
                      Metamask={Metamask}
                      WalletC={WalletC}
                    />
                  }
                />
                <Route
                  path="/forgot_password"
                  element={
                    <Forgot_password
                      url={url}
                      Dissconnect={Dissconnect}
                      Metamask={Metamask}
                      WalletC={WalletC}
                    />
                  }
                />
                <Route
                  path="/ChangeEmail"
                  element={
                    <Change_Email
                      url={url}
                      Dissconnect={Dissconnect}
                      Metamask={Metamask}
                      WalletC={WalletC}
                    />
                  }
                />
              </>
            )}

            <Route
              path="/currencies/:project_api_named"
              element={
                <Crypto_detail
                  url={url}
                  Dissconnect={Dissconnect}
                  Metamask={Metamask}
                  WalletC={WalletC}
                />
              }
            />
            <Route path="*" element={<Page404 />} />
            <Route path="/profile" element={<Profile />} />
            {signup || admin_login ? (
              <>
                {" "}
                <Route
                  path="/admin"
                  element={
                    <Admin
                      url={url}
                      Dissconnect={Dissconnect}
                      Metamask={Metamask}
                      WalletC={WalletC}
                    />
                  }
                />{" "}
                <Route
                  path="/admin_detail/:name/:id"
                  element={
                    <Admin_Detail
                      url={url}
                      Dissconnect={Dissconnect}
                      Metamask={Metamask}
                      WalletC={WalletC}
                    />
                  }
                />
              </>
            ) : (
              ""
            )}
            {admin_login ? (
              ""
            ) : (
              <Route
                path="/admin_login"
                element={
                  <Admin_login
                    url={url}
                    Dissconnect={Dissconnect}
                    Metamask={Metamask}
                    WalletC={WalletC}
                  />
                }
              />
            )}
            <Route
              path="/request_list"
              element={
                <List_request
                  url={url}
                  Dissconnect={Dissconnect}
                  Metamask={Metamask}
                  WalletC={WalletC}
                />
              }
            />

            <Route
              path="/request_list_detail/:name/:id"
              element={
                <Request_list_detail
                  url={url}
                  Dissconnect={Dissconnect}
                  Metamask={Metamask}
                  WalletC={WalletC}
                />
              }
            />
            <Route
              path="/methodology"
              element={
                <Methodology
                  url={url}
                  Dissconnect={Dissconnect}
                  Metamask={Metamask}
                  WalletC={WalletC}
                />
              }
            />

            <Route
              path="/requests/new"
              element={
                <Create_form
                  url={url}
                  Dissconnect={Dissconnect}
                  Metamask={Metamask}
                  WalletC={WalletC}
                />
              }
            />

            <Route
              path="/watchlist/:addr"
              element={
                <Watchlist
                  url={url}
                  Dissconnect={Dissconnect}
                  Metamask={Metamask}
                  WalletC={WalletC}
                />
              }
            />
            <Route
              path="/ResetPassword"
              element={
                <ResetPassword
                  url={url}
                  Dissconnect={Dissconnect}
                  Metamask={Metamask}
                  WalletC={WalletC}
                />
              }
            />
          </Routes>
        </Router>
      </QueryProvider>
    </div>
  );
}

export default App;
