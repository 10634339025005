/* eslint-disable no-shadow */
import React, { PureComponent, useState, useEffect } from "react";
import { PieChart, Pie, Cell } from "recharts";
import moment from "moment";

const RADIAN = Math.PI / 180;
const data = [
  { name: "A", value: 45, color: "#F02934" },
  { name: "B", value: 45, color: "#F07D29" },
  { name: "C", value: 45, color: "#9ACB82" },
  { name: "D", value: 45, color: "#34B349" },
];
const cx = 150;
const cy = 200;
const iR = 50;
const oR = 100;

const Example = () => {
  const [value, setValue] = useState(50); // Initial value
  const [fearAndGreedData, setFearAndGreedData] = useState({});
  const [value_fear, setvalue_fear] = useState();
  const [time, settime] = useState();

  useEffect(() => {
    // Fetch data from your API
    fetch("https://api.alternative.me/fng/?limit=3")
      .then((response) => response.json())
      .then((data) => {
        if (data && data.data && data.data.length > 0) {
          const fetchedValue = parseInt(data.data[0].value, 10);
          setValue(fetchedValue);
          setvalue_fear(data.data[0].value_classification);
          settime(data.data[0].timestamp);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const needle = (value, cx, cy, iR, oR, color) => {
    const ang = 180 - (value / 100) * 180; // Calculate angle based on value
    const length = (iR + 2 * oR) / 3;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 5;
    const x0 = cx + 5;
    const y0 = cy + 5;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    return [
      <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
      <path
        d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
        stroke="#none"
        fill={color}
      />,
    ];
  };

  return (
    <div className="ps-r">
      <div className="ps-a z-i-999 m-a f-s-1_25 f-w-600 m-t-2 w-100">
        Now : {value} {value_fear}
      </div>
      <div className="">
        <PieChart width={300} height={250}>
          <Pie
            dataKey="value"
            startAngle={180}
            endAngle={0}
            data={data}
            cx={cx}
            cy={cy}
            innerRadius={iR}
            outerRadius={oR}
            fill="#8884d8"
            stroke="none"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          {needle(value, cx, cy, iR, oR, "#d0d000")}
        </PieChart>
      </div>
      <div className="t-a-c f-s-0_8">
        Last Update : {moment.unix(time).fromNow()}{" "}
      </div>
    </div>
  );
};

export default Example;
