import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";

function Disclaimer({ url, Dissconnect, Metamask, WalletC }) {
  return (
    <div>
      <Navbar Dissconnect={Dissconnect} Metamask={Metamask} WalletC={WalletC} />
      <div className="container-kws">
        <p class="c0">
          <span class="f-s-2 f-w-600">DISCLAIMERS</span>
        </p>
        <p class="c0">
          <span class="c1">
            The Virtual Event is held and presented to Registered Participants
            on an "as is" basis. All views, opinions, positions and
            representations made to you as part of the Virtual Event (including
            but not limited to those made by sponsors, attendees, speakers,
            presenters and other Registered Participants) are theirs alone and
            do not necessarily reflect the views, opinions and positions of RMC.
            RMC makes no such representation to you in this regard.
          </span>
        </p>
        <p class="c0">
          <span class="c1">
            RMC also makes no warranties to you in respect of any and all
            aspects of the Virtual Events (including but not limited to the
            accuracy, reliability, suitability or completeness of any and all
            contents and materials presented and made available to you as part
            of your participation in the Virtual Events). To the fullest extent
            possible under applicable laws, RMC disclaims all implied
            warranties. You also agree and accept that RMC shall not be liable
            for any errors, omissions, or inaccuracies in respect of the content
            and materials presented and made available to you as part of your
            participation in the Virtual Events.
          </span>
        </p>
        <p class="c0 c5">
          <span class="c1"></span>
        </p>
        <p class="c0">
          <span class="c7">LIMITATION OF LIABILITY &amp; INDEMNIFICATION</span>
        </p>
        <p class="c0">
          <span class="c1">
            In no event shall RMC (and its respective officers, directors,
            employees, members, agents, and affiliates) be liable for any
            indirect, punitive, incidental, special, or consequential damages or
            damages for lost profits, arising out of, or in any way connected
            with, your access to or participation in the Virtual Event,
            including but not limited to damages relating to: (i) any change to
            the Virtual Event date, (ii) inability to access, display, or view
            the streaming of the Virtual Event due to technical issues or
            otherwise; or (iii) otherwise arising out of the access to or
            participation in the Virtual Event, whether based on a theory of
            negligence, contract, tort, strict liability, or otherwise, and even
            if RMC has been advised of the possibility of such damages.
          </span>
        </p>
        <p class="c0 c5">
          <span class="c1"></span>
        </p>
        <p class="c0">
          <span class="c1">
            In no event shall RMC (and its respective officers, directors,
            employees, members, agents, and affiliates) be liable to you for any
            claims, proceedings, liabilities, obligations, damages, losses or
            costs in excess of US$100.00. The limitation of liability reflects
            the allocation of risk between the parties. The limitations
            specified in this clause will survive and apply even if any limited
            remedy specified in these VE Terms is found to have failed of its
            essential purpose.
          </span>
        </p>
        <p class="c0">
          <span class="c1">
            Some jurisdictions do not allow the exclusion or limitation of
            incidental or consequential damages, so the above limitations or
            exclusions may not apply to you. These terms give you specific legal
            rights, and you may also have other rights which vary from
            jurisdiction to jurisdiction. The disclaimers, exclusions, and
            limitations of liability under these terms will not apply to the
            extent prohibited by applicable law.
          </span>
        </p>
        <p class="c0 c5">
          <span class="c1"></span>
        </p>
        <p class="c0">
          <span class="c1">
            Except to the extent prohibited under applicable law, you shall
            indemnify, defend and hold harmless RMC and its members, managers,
            directors, officers, employees, partners, consultants, contractors,
            service providers, agents, affiliates, successors and assigns from
            and against any and all suits, actions, proceedings and claims by
            third parties (whether threatened or actual), and all losses,
            liabilities, damages, judgments, costs and expenses (including
            reasonable legal fees) arising out of, relating to or in connection
            with: (i) your participation in the Virtual Event, and use (or
            misuse) of contents obtained through such participation; (ii) your
            violation of any of these VE Terms; (iii) your violation of any
            applicable law, rule or regulation; (iv) any claim that your actions
            and/or conduct had caused damage to, infringed upon, misappropriated
            or otherwise violated the rights of any third party, including
            infringement, misappropriation or other violation of third-party
            intellectual property rights, or violation of any right of privacy
            or publicity; and/or (v) any dispute that you have with any third
            party relating to or in connection with your participation in the
            Virtual Event. RMC reserves the right, at its own cost, to assume
            the exclusive defense and control of any matter otherwise subject to
            indemnification by you, in which event you will fully cooperate with
            RMC in asserting any available defenses and in the conduct of such
            defense.
          </span>
        </p>
        <p class="c0">
          <span class="c1">
            For clarity, the rights and protections afforded to RMC under these
            VE Terms shall be in addition to the rights and protections that RMC
            is entitled to under the RMC Terms of Use and RMC Privacy Policy.
          </span>
        </p>
        <p class="c0 c5">
          <span class="c1"></span>
        </p>
        <p class="c0">
          <span class="c7">GOVERNING LAW AND JURISDICTION</span>
        </p>
        <p class="c0">
          <span class="c1">
            These VE Terms and any and all claims, disputes or other legal
            proceedings by or between you and us, including but not limited to
            any claims or disputes that are in any way related to or arising out
            of these VE Terms or your access to or participation in the Virtual
            Event, shall be governed by and construed in accordance with U.S.
            federal law and the laws of Delaware, without regard to any
            principles of conflicts of law. You agree that any action arising
            out of, relating to or in connection with your participation in the
            Virtual Events, and/or these Terms shall be litigated solely in a
            state or federal court of competent jurisdiction located in
            Delaware, and you further irrevocably submit to the exclusive
            jurisdiction of such court and waive any objection to jurisdiction
            and venue (including on the basis of forum non-conveniens).
          </span>
        </p>
        <p class="c0 c5">
          <span class="c1"></span>
        </p>
        <p class="c0">
          <span class="c7">GENERAL</span>
        </p>
        <p class="c0">
          <span class="c1">
            In no event shall RMC be responsible or liable for any failure or
            delay in the performance of its obligations hereunder arising out of
            or caused by, directly or indirectly, forces beyond its control,
            including, without limitation, strikes, work stoppages, accidents,
            acts of war or terrorism, civil or military disturbances, pandemics,
            nuclear or natural catastrophes or acts of God, and interruptions,
            loss or malfunctions of utilities, communications or computer
            (software and hardware) services.
          </span>
        </p>
        <p class="c0 c5">
          <span class="c1"></span>
        </p>
        <p class="c0">
          <span class="c1">
            If any provision of these VE Terms, including, but not limited to,
            the warranty disclaimers and limitations of liability set forth
            above, is determined to be invalid or unenforceable under applicable
            law, the invalid or unenforceable provisions in these Terms shall be
            deemed superseded by valid and enforceable provisions that, to the
            extent possible, fulfill the business purposes and intent of such
            invalid and unenforceable provisions.
          </span>
        </p>
        <p class="c0">
          <span class="c1">
            Any provision of the above VE Terms that is declared invalid shall
            be deemed severable and shall not affect the validity or
            enforceability of the remainder.
          </span>
        </p>
        <p class="c0 c5">
          <span class="c1"></span>
        </p>
        <p class="c0">
          <span>
            You agree that RMC may use your personal data for the purpose of
            performing its obligations under these VE Terms and/or providing
            services relating to the conduct of the Virtual Event, including for
            the purpose of event registration, communications, addressing issues
            relating to event access, invoicing, payment, solicitation of
            post-event feedback, participant verification and other
            administration relating thereto. For clarity, the RMC Privacy Policy
            (accessible here:{" "}
          </span>
          <span class="c6">
            <a
              class="c23"
              href="https://www.google.com/url?q=https://ramzmarketcap.com/privacy/&amp;sa=D&amp;source=editors&amp;ust=1695950451438202&amp;usg=AOvVaw0Z6pmQ3k08NWYp7e4q_iVg"
            >
              https://ramzmarketcap.com/privacy/
            </a>
          </span>
          <span class="c1">&nbsp;) shall also apply.</span>
        </p>
        <p class="c0">
          <span class="c1">
            Any heading or title contained herein is for convenience of
            reference only and shall not affect the meaning or interpretation of
            these VE Terms. The terms "include" and "including" are deemed to
            include the phrase "without limitation" immediately thereafter.
          </span>
        </p>
        <p class="c0">
          <span class="c1">
            Unless expressly provided to the contrary in these VE Terms, a
            person who is not a party to these VE Terms has no rights at law and
            in equity to enforce or enjoy the benefit of any term in these VE
            Terms.
          </span>
        </p>
        <p class="c0 c5">
          <span class="c1"></span>
        </p>
        <p class="c0 c5">
          <span class="c1"></span>
        </p>
        <p class="c0 c5">
          <span class="c1"></span>
        </p>
        <p class="c0 c5">
          <span class="c1"></span>
        </p>
        <p class="c0 c5">
          <span class="c1"></span>
        </p>
        <p class="c0 c5">
          <span class="c1"></span>
        </p>
        <p class="c0 c5">
          <span class="c1"></span>
        </p>
        <p class="c0 c5">
          <span class="c1"></span>
        </p>
        <p class="c0 c5">
          <span class="c1"></span>
        </p>
        <p class="c0 c5">
          <span class="c1"></span>
        </p>
        <p class="c0 c5">
          <span class="c1"></span>
        </p>
        <p class="c0 c5">
          <span class="c1"></span>
        </p>
        <p class="c0 c5">
          <span class="c1"></span>
        </p>
        <p class="c0 c5">
          <span class="c1"></span>
        </p>
        <p class="c0 c5">
          <span class="c1"></span>
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Disclaimer;
