import React, { useEffect } from "react";
import axios from "axios";

function TEst2({ url }) {
  const apis =
    "https://pro-api.coinmarketcap.com/v1/cryptocurrency/quotes/latest?symbol=BTC&CMC_PRO_API_KEY=62751589-8b9c-44d7-b38f-405da98d7b10";
  const apiEndpoint =
    "https://pro-api.coinmarketcap.com/v1/cryptocurrency/quotes/latest?symbol=BTC&CMC_PRO_API_KEY=YOUR_API_KEY_HERE"; // Replace with your actual API endpoint

  const fetchData = async () => {
    try {
      const response = await axios.post(`${url}/api/coinmarketcap`, {
        apiEndpoint: apis,
      });
      console.log("price", response.data.data.BTC.quote.USD.price);
      console.log(
        "usd_24h_change",
        response.data.data.BTC.quote.USD.percent_change_24h,
      );
      console.log("market_cap", response.data.data.BTC.quote.USD.market_cap);
      console.log("24h_volume", response.data.data.BTC.quote.USD.volume_24h);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [url]);

  return <div>fafds</div>;
}

export default TEst2;
