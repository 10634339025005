import React, { useEffect, useState } from "react";
import axios from "axios";
import Super_admin_navbar from "./Super_admin_navbar";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";

function Super_admin({ url }) {
  const [remark, setremarks] = useState([]);

  const getting_remark = () => {
    try {
      axios.get(`${url}/admin_list`).then((res) => {
        console.log("cdfdsfda", res.data);
        setremarks(res.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getting_remark();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${url}/admin_list_delete/${id}`);
      window.location.reload();
    } catch (error) {
      console.error("Error deleting admin:", error);
    }
  };

  return (
    <div>
      {" "}
      <Super_admin_navbar />
      <div className="container-kws">
        <div className="f-s-1_5 t-a-c f-w-600 m-t-4">List of Admin</div>
        <div className="bg_violet b-r-15 pa-2 m-b-4 m-t-2">
          {" "}
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              background: "transparent",
            }}
          >
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className="f-w-600">
                    <TableCell align="center" style={{ minWidth: "170" }}>
                      S.No.
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: "170" }}>
                      Name
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: "170" }}>
                      Email
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: "170" }}>
                      Username
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: "170" }}>
                      Update
                    </TableCell>

                    <TableCell align="center" style={{ minWidth: "170" }}>
                      Delete
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {remark &&
                    remark.map((res, index) => {
                      return (
                        <>
                          <TableRow hover role="checkbox" tabIndex={-1}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">{res.name}</TableCell>
                            <TableCell align="center">{res.email}</TableCell>
                            <TableCell align="center">{res.username}</TableCell>

                            <TableCell align="center">
                              <Link to={`/Create_login_update/${res._id}`}>
                                <button className="login b_clr_blue b_clr f-w-600  b-1 b-c-t pa-0_5 c-w b-r-10 p-x-1_5 p-y-0_5 m-l-2">
                                  Update
                                </button>
                              </Link>
                            </TableCell>
                            <TableCell align="center">
                              {" "}
                              <button
                                className="login b_clr_blue b_clr f-w-600  b-1 b-c-t pa-0_5 c-w b-r-10 p-x-1_5 p-y-0_5 m-l-2"
                                onClick={() => handleDelete(res._id)}
                              >
                                Delete
                              </button>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                </TableBody>
              </Table>
              {remark.length > 0 ? (
                ""
              ) : (
                <div className="p-y-5 t-a-c c-w f-w-600">
                  No data to show.....
                </div>
              )}
            </TableContainer>
          </Paper>
        </div>
      </div>
    </div>
  );
}

export default Super_admin;
