import React, { useState, useEffect } from "react";
import axios from "axios";
import { Sparklines, SparklinesLine } from "react-sparklines";

const SparklineChart = ({ api }) => {
  const [data, setData] = useState([]);
  const [chartColor, setChartColor] = useState("#ff5959"); // Default to red color

  useEffect(() => {
    const fetchData = async (api) => {
      try {
        const response = await axios.get(`${api}7`); // Removed the "7" from the API URL
        const prices = response.data; // Assuming your API directly provides an array of data

        const firstValue = parseFloat(prices[0][1]);
        const lastValue = parseFloat(prices[prices.length - 1][1]);

        // Determine the chart color based on the condition
        if (firstValue < lastValue) {
          setChartColor("#59ff8b"); // Green color
        } else {
          setChartColor("#FF0000"); // Red color
        }

        setData(prices.map((point) => parseFloat(point[1])));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(api);
  }, [api]);

  return (
    <div>
      <Sparklines data={data} width={100} height={50}>
        <SparklinesLine color={chartColor} />
      </Sparklines>
    </div>
  );
};

export default SparklineChart;
