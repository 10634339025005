import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Footer from "./Footer";
import axios from "axios";
import Navbar from "./Navbar";
import { Link, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import project_api from "../Image/project_api.png";
import { Backdrop, CircularProgress } from "@mui/material";

const notify = (msg) => toast.success(msg);
const notifyError = (msg) => toast.error(msg);
const notifyMessage = (msg) =>
  toast(msg, {
    duration: 10000,
  });

function Create_form({ url, Dissconnect, Metamask, WalletC }) {
  const [value, setvalues] = useState();
  const [open, setOpen] = useState(false);
  const [email, setemails] = useState();
  const [subject, setsubjects] = useState();
  const [Review, setReviews] = useState();
  const [Terms, setTermss] = useState();
  const [Declaration, setDeclarations] = useState();
  const [Disclaimer, setDisclaimers] = useState();
  const [relationship_project, setrelationship_projects] = useState();
  const [project_launch_date, setproject_launch_dates] = useState();
  const [project_name, setproject_names] = useState();
  const [project_ticker_symbol, setproject_ticker_symbols] = useState();
  const [cryptoasset, setcryptoassets] = useState();
  const [one_liner_description, setone_liner_descriptions] = useState();
  const [detailed_project_description, setdetailed_project_descriptions] =
    useState();
  const [platform, setplatforms] = useState();
  const [hashing_consensus_algorithm, sethashing_consensus_algorithms] =
    useState();
  const [team_backers_investors, setteam_backers_investorss] = useState();
  const [media_coverage, setmedia_coverages] = useState();
  const [traction_adoption, settraction_adoptions] = useState();
  const [country_origin, setcountry_origins] = useState();
  const [link_logo, setlink_logos] = useState();
  const [website, setwebsites] = useState();
  const [Annex_A, setAnnex_As] = useState();
  const [Emission, setEmissions] = useState();
  const [platform_contract_address, setplatform_contract_addresss] = useState();
  const [contract_address, setcontract_addresss] = useState();
  const [no_of_decimal, setno_of_decimals] = useState();
  const [block_explorer, setblock_explorers] = useState();
  const [source_code, setsource_codes] = useState();
  const [whitepaper, setwhitepapers] = useState();
  const [announcement, setannouncements] = useState();
  const [project_api_name, setproject_api_names] = useState();
  const [message_board, setmessage_boards] = useState();
  const [twitter, settwitters] = useState();
  const [reddit, setreddits] = useState();
  const [facebook, setfacebooks] = useState();
  const [project_introduction, setproject_introductions] = useState();
  const [video_channel, setvideo_channels] = useState();
  const [chat, setchats] = useState();
  const [linkedin, setlinkedins] = useState();
  const [mobile_app, setmobile_apps] = useState();
  const [circulating_supply, setcirculating_supplys] = useState();
  const [total_supply, settotal_supplys] = useState();
  const [max_supply, setmax_supplys] = useState();
  const [ico_start_date, setico_start_dates] = useState();
  const [ico_end_date, setico_end_dates] = useState();
  const [ico_launch_price, setico_launch_prices] = useState();
  const [api_live_price, setapi_live_prices] = useState();
  const [graph_historical_api, setgraph_historical_apis] = useState();
  const [cmc_supported_exchange, setcmc_supported_exchanges] = useState();
  const [attachment, setAttachment] = useState(null);
  const [community, setcommunitys] = useState();
  // const [address, setaddresss] = useState();

  const handleFileChange = (e) => {
    setAttachment(e.target.files[0]);
  };

  const { name, id } = useParams();

  const handleFileUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", attachment);

      const response = await axios.post("/api/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("File uploaded successfully:", response.data);
      // Do something with the response, like displaying a success message or updating the UI.
    } catch (error) {
      console.error("Error uploading file:", error);
      // Handle the error and display an error message or take appropriate action.
    }
  };

  const address = JSON.parse(localStorage.getItem("address"));
  console.log("ad", address);

  console.log("url", url, id, address);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(`${url}/update/${id}/${address}`, {
        address: address,
        email: email,
        subject: subject,
        relationship_project: relationship_project,
        project_launch_date: project_launch_date,
        project_name: project_name,
        project_ticker_symbol: project_ticker_symbol,
        cryptoasset: cryptoasset,
        community: community,
        one_liner_description: one_liner_description,
        detailed_project_description: detailed_project_description,
        platform: platform,
        hashing_consensus_algorithm: hashing_consensus_algorithm,
        team_backers_investors: team_backers_investors,
        media_coverage: media_coverage,
        traction_adoption: traction_adoption,
        country_origin: country_origin,
        link_logo: link_logo,
        website: website,
        Annex_A: Annex_A,
        Emission: Emission,
        platform_contract_address: platform_contract_address,
        contract_address: contract_address,
        no_of_decimal: no_of_decimal,
        block_explorer: block_explorer,
        source_code: source_code,
        whitepaper: whitepaper,
        announcement: announcement,
        message_board: message_board,
        twitter: twitter,
        reddit: reddit,
        facebook: facebook,
        project_api_name: project_api_name,
        project_introduction: project_introduction,
        video_channel: video_channel,
        chat: chat,
        linkedin: linkedin,
        mobile_app: mobile_app,
        circulating_supply: circulating_supply,
        total_supply: total_supply,
        max_supply: max_supply,
        ico_start_date: ico_start_date,
        ico_end_date: ico_end_date,
        ico_launch_price: ico_launch_price,
        api_live_price: api_live_price,
        graph_historical_api: graph_historical_api,
        cmc_supported_exchange: cmc_supported_exchange,
      });

      console.log("Response:", response.data);
      setOpen(false);
      notify("Succesful updated waiting for status approved");
      // Handle success and feedback to the user
    } catch (error) {
      console.error("Error:", error);
      // Handle error and provide feedback to the user
    }
  };

  useEffect(() => {
    PackagesDetails();
  }, []);

  // const [crypt, setcryptos] = useState([]);

  const PackagesDetails = () => {
    try {
      axios.get(`${url}/api/crypto/${name}`).then((res) => {
        console.log("gettingpacakgecarddetails", res.data);
        // setAddress(res.data.address);
        setemails(res.data.email);
        setsubjects(res.data.subject);
        setReviews(res.data.Review);
        setTermss(res.data.Terms);
        setDeclarations(res.data.Declaration);
        setDisclaimers(res.data.Disclaimer);
        setrelationship_projects(res.data.relationship_project);
        setproject_launch_dates(res.data.project_launch_date);
        setproject_names(res.data.project_name);
        setproject_ticker_symbols(res.data.project_ticker_symbol);
        setcryptoassets(res.data.cryptoasset);
        setone_liner_descriptions(res.data.one_liner_description);
        setdetailed_project_descriptions(res.data.detailed_project_description);
        setplatforms(res.data.platform);
        sethashing_consensus_algorithms(res.data.hashing_consensus_algorithm);
        setteam_backers_investorss(res.data.team_backers_investors);
        setmedia_coverages(res.data.media_coverage);
        settraction_adoptions(res.data.traction_adoption);
        setcountry_origins(res.data.country_origin);
        setlink_logos(res.data.link_logo);
        setwebsites(res.data.website);
        setAnnex_As(res.data.Annex_A);
        setEmissions(res.data.Emission);
        setplatform_contract_addresss(res.data.platform_contract_address);
        setcontract_addresss(res.data.contract_address);
        setno_of_decimals(res.data.no_of_decimal);
        setblock_explorers(res.data.block_explorer);
        setsource_codes(res.data.source_code);
        setwhitepapers(res.data.whitepaper);
        setannouncements(res.data.announcement);
        setmessage_boards(res.data.message_board);
        settwitters(res.data.twitter);
        setreddits(res.data.reddit);
        setfacebooks(res.data.facebook);
        setproject_introductions(res.data.project_introduction);
        setvideo_channels(res.data.video_channel);
        setchats(res.data.chat);
        setlinkedins(res.data.linkedin);
        setmobile_apps(res.data.mobile_app);
        setcirculating_supplys(res.data.circulating_supply);
        settotal_supplys(res.data.total_supply);
        setmax_supplys(res.data.max_supply);
        setico_start_dates(res.data.ico_start_date);
        setico_end_dates(res.data.ico_end_date);
        setico_launch_prices(res.data.ico_launch_price);
        setapi_live_prices(res.data.api_live_price);
        setgraph_historical_apis(res.data.graph_historical_api);
        setcmc_supported_exchanges(res.data.cmc_supported_exchange);
        setAttachment(res.data.attachment);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {" "}
      <Navbar Dissconnect={Dissconnect} Metamask={Metamask} WalletC={WalletC} />
      <div className="container-kws ">
        <form onSubmit={handleSubmit}>
          <div className="max-w-50">
            <div className="f-s-2 f-w-600 m-b-1_5">Submit a request</div>
            <div className="m-b-0_5">
              Please review ALL the options and select the CORRECT form to
              ensure that your request gets routed to the correct team. Priority
              will be given to requesters that submit well-structured,
              actionable, and complete information to the CORRECT form.
            </div>
            <div className="m-b-1_5">
              {" "}
              <Form.Select aria-label="Default select example">
                <option>-</option>
                <option value="1">1 - [New Listing] Add cryptoasset</option>
                <option value="2">
                  1a - [New listing] ICO Calendar Form redirect [Apply here
                  instead - https://tinyurl.com/yvwf7ra3]
                </option>
                <option value="3">2 - [New Listing] Add exchange</option>
                <option value="3">
                  3 - [Existing Cryptoasset & Exchange] Add market/pair{" "}
                </option>
                <option value="3">
                  4 - [Existing Cryptoasset] Update supply figures
                </option>
                <option value="3">
                  5 - [Existing Cryptoasset] Coin/Token Swap
                </option>
                <option value="3">
                  6 - [Existing Exchange] Update info (e.g. Fiat on-ramp,
                  Rebrand, API update)
                </option>
                <option value="3">
                  7 - [Existing Cryptoasset] Update info (e.g. Rebrand, URL
                  update, Tagging)
                </option>
                <option value="3">
                  8 - [Self-reporting Dashboard] Cryptoassets/KOLs/Organizations
                  (official representatives only)
                </option>
                <option value="3">
                  9 - [Self-reporting Dashboard] Exchanges (official
                  representatives only)
                </option>
                <option value="3">
                  10 - Add a new event to the events calendar
                </option>
                <option value="3">11 - Report a bug</option>
                <option value="3">
                  12 - Feedback, Questions, Complaints, Account Deletion, &
                  Privacy Data Inquiry
                </option>
                <option value="3">13 - Feature Request</option>
                <option value="3">
                  14 - [New Listing] Add Interest Product [Under Maintenance]
                </option>
                <option value="3">
                  15 - [Headlines by RamzMarketCap] Application [Under
                  maintenance]
                </option>
                <option value="3">
                  16 - RamzMarketCap API Inquiries [pro.ramzmarketcap.com]
                </option>
                <option value="3">17 - Earn Campaign</option>
                <option value="3">18 - CMC Airdrop Campaign</option>
                <option value="3">
                  18a - Diamond Campaign (Please follow these instructions -
                  https://tinyurl.com/yh4cpxrb)
                </option>
                <option value="3">
                  19 - Mobile App Support [iOS and Android]
                </option>
              </Form.Select>
            </div>
            <div className=" m-b-0_5">Your email address</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={email}
                onChange={(e) => setemails(e.target.value)}
              />{" "}
            </div>
            <div className=" m-b-0_5">
              Subject Field (Please adhere to the format)
            </div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={subject}
                onChange={(e) => setsubjects(e.target.value)}
              />
              <div className="">
                Please refer to this format as an example. [Project's full name]
                - [Symbol] - [Request, e.g. Add Market, Add
                cryptoasset/exchange, Update cryptoasset/exchange, update
                supply]
              </div>
            </div>
            <div className=" m-b-0_5">Methodology Review</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="checkbox"
                className=" b-1 b-r-5 f-s-1  pa-0_5"
              />{" "}
              &nbsp; I declare that I have read, understood, and fully agree to
              the terms and conditions stipulated at
              <Link to="/methodology">
                https://ramzmarketcap.com/methodology/
              </Link>
            </div>
            <div className=" m-b-0_5">Support Terms</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="checkbox"
                className=" b-1 b-r-5 f-s-1  pa-0_5"
              />{" "}
              &nbsp;I acknowledge that RamzMarketCap reserves the right, in
              their sole discretion, to determine the suitability of my request
              for the site. In the event that RamzMarketCap rejects my request,
              I acknowledge that RamzMarketCap is not obligated to inform me or
              provide any reasons for such refusal.
            </div>
            <div className=" m-b-0_5">Accuracy Declaration</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="checkbox"
                className=" b-1 b-r-5 f-s-1  pa-0_5"
              />{" "}
              &nbsp; I declare that the information provided on the form is
              accurate and complete to the best of my knowledge and that failure
              to fulfill these requirements may render my request inadmissible.
            </div>
            <div className=" m-b-0_5">Listings Disclaimer</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="checkbox"
                className=" b-1 b-r-5 f-s-1  pa-0_5"
              />{" "}
              &nbsp; RamzMarketCap listings are always free. Please beware of
              spoofed emails, fake social accounts, or individuals requesting
              listing fees. Anyone asking you to pay a fee or tip for getting
              your project listed on RamzMarketCap is definitely not sanctioned
              to do so. We also do not sanction any listing service to assist in
              the listing application of any cryptoasset project or exchange.
              Please review this - https://tinyurl.com/2hpml4hk
            </div>{" "}
            <div className=" m-b-0_5">Relationship with the Project</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <textarea
                rows={8}
                className="w-100 b-c-t b-1 border_clr_w  o-1  b-r-5  pa-0_5"
                value={relationship_project}
                onChange={(e) => setrelationship_projects(e.target.value)}
              />{" "}
              Please describe your relationship with the project (e.g. CEO,
              founder, employee, community member and exchange)
            </div>
            <div className=" m-b-0_5">Project Launch Date</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={project_launch_date}
                onChange={(e) => setproject_launch_dates(e.target.value)}
              />{" "}
              The date the project launched (e.g. token sale, genesis block,
              formation of company, drafting of business plan) Where possible,
              provide evidence/links for verification.
            </div>
            <div className=" m-b-0_5">Project Name</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={project_name}
                onChange={(e) => setproject_names(e.target.value)}
              />
              Please provide the FULL name of the project (e.g. Bitcoin,
              Ethereum, Ripple, Holochain, Binance, Bittrex).
            </div>
            <div className=" m-b-0_5">
              Project Ticker/Symbol (Do not put a $ sign if there is none in the
              ticker)
            </div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={project_ticker_symbol}
                onChange={(e) => setproject_ticker_symbols(e.target.value)}
              />
              (e.g. BTC, ETH, XRP, HOT)
            </div>
            <div className=" m-b-0_5">Cryptoasset Tags - Sector/Categories</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={cryptoasset}
                onChange={(e) => setcryptoassets(e.target.value)}
              />
              You may select more than one tag. Doing so would allow users to
              filter assets by category membership.
            </div>
            <div className=" m-b-0_5">One-liner description</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={one_liner_description}
                onChange={(e) => setone_liner_descriptions(e.target.value)}
              />
              Provide a snappy and concise tagline for the project. E,g, KAVA -
              A Cross-Chain Platform for Decentralized Finance. Band Protocol -
              Secure and scalable decentralized oracle for Web3.0. See more
              examples here: https://research.binance.com/
            </div>
            <div className=" m-b-0_5">
              Detailed Project Description (Cryptoasset)
            </div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={detailed_project_description}
                onChange={(e) =>
                  setdetailed_project_descriptions(e.target.value)
                }
              />
              Our data shows that our users spend more time on pages that use
              descriptions that adhere to this template:
              https://tinyurl.com/yy2mw9v7. Recommended word count: 450 - 600
              words. Provide a detailed description of your project, which may
              be used on the asset page
              (https://ramzmarketcap.com/currencies/cardano/), Minimize the use
              of hyperbole, superlatives, and redundant statements (e.g.
              leading, amazing, best, first of its kind, state of the art,
              decentralized blockchain). It should not read like a sales pitch.
              Focus on factual statements about your project. Write in
              third-person. Be concise - avoid redundant statements. (e.g.
              decentralized blockchain). For example, Launched in [dd/mm/yyyy]
              by a team based in [country], [project] is a smart contract
              platform that enables developers to build decentralized
              applications. See https://ramzmarketcap.com/currencies/cardano/.
            </div>{" "}
            <div className=" m-b-0_5">
              Platform (e.g. EOS, NEO, Ethereum, proprietary blockchain).
            </div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={platform}
                onChange={(e) => setplatforms(e.target.value)}
              />
              Please specify all token platforms that the asset is currently on.
              For example, CHZ has ERC20 and BEP20 tokens and is therefore on
              the Ethereum and Binance blockchains.
            </div>
            <div className=" m-b-0_5">Team/Backers/Investors</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={team_backers_investors}
                onChange={(e) => setteam_backers_investorss(e.target.value)}
              />
              Provide a list of key employees, investors, and advisors. Describe
              their core competencies and past experience/achievements, and
              explain their involvement in your project. Where possible, provide
              evidence/links for verification.
            </div>
            <div className=" m-b-0_5">
              Country of Origin (where majority of team is located)
            </div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={country_origin}
                onChange={(e) => setcountry_origins(e.target.value)}
              />
              Please indicate the country where the majority of your team is
              located. If applicable, please provide a list of countries and
              indicate the location of your headquarters.
            </div>
            <div className=" m-b-0_5">Link to Logo</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={link_logo}
                onChange={(e) => setlink_logos(e.target.value)}
              />{" "}
              3 conditions MUST be met: (1) Transparent background; (2) Square
              (200x200); unequal dimensions will be rejected! (3) PNG format.
              See https://onlinepngtools.com/create-transparent-png and
              https://www.photoresizer.com/. If possible, it is recommended that
              you provide logo URLs ending with .png so that our system can
              extract the logo directly.
            </div>
            <div className=" m-b-0_5">Website 1</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={website}
                onChange={(e) => setwebsites(e.target.value)}
              />
              There is data validation for this field. Kindly ensure that your
              URL conforms to the following format: https://www.website.com. If
              you still have issues, feel free to insert a placeholder link. You
              can then add the real URL in the comment/proof section in the last
              field of this form.
            </div>
            <div className=" m-b-0_5">
              Annex A - Rich List & Reserve Addresses (Mandatory for Circulating
              Supply/Ranking)(optional)
            </div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={Annex_A}
                onChange={(e) => setAnnex_As(e.target.value)}
              />
              Paste the URL of your completed Annex A Google Sheet here. Please
              refer to the instructions in Cell A1 of this link and ensure that
              your submission is in the CORRECT format:
              https://docs.google.com/spreadsheets/d/1ON2o9fZtdj6aa_uaT7ALtGx1VxFnIDUi8-uS-fWji0o/edit#gid=1181839735.
            </div>
            <div className=" m-b-0_5">Emission/release schedule(optional)</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={Emission}
                onChange={(e) => setEmissions(e.target.value)}
              />
              Shows the emission/release schedule of the asset over time. See
              examples - https://skale.network/tokenomics,
              https://geeq.io/tokenomics/, https://www.orchid.com/oxt
            </div>
            <div className=" m-b-0_5">Platform of Contract Address 1</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={platform_contract_address}
                onChange={(e) => setplatform_contract_addresss(e.target.value)}
              />
              Select '-' if the platform is not in the dropdown
            </div>
            <div className=" m-b-0_5">Contract Address 1</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={contract_address}
                onChange={(e) => setcontract_addresss(e.target.value)}
              />
              For MOVE contracts (e.g. APTOS, SUI), pls follow this format:
              0xebbf537bc3686be32fe22b498b42715641bbb209267be72236a352e0444cc5df::sui_pepe::SUI_PEPE
              Type 'NA' if there is no contract yet (for untracked listings).
            </div>
            <div className=" m-b-0_5">
              Number of Decimals (Contract Address 1)(optional)
            </div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={no_of_decimal}
                onChange={(e) => setno_of_decimals(e.target.value)}
              />
              This is usually found on the explorer page of the asset. See
              example - https://gyazo.com/7e2b24ca517cd90fd3a36f83d7c0a88f
            </div>
            <div className=" m-b-0_5">Community</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={community}
                onChange={(e) => setcommunitys(e.target.value)}
              />
              Insert a valid URL.
            </div>
            <div className=" m-b-0_5">
              Source Code (e.g. Github, Gitter)(optional)
            </div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={source_code}
                onChange={(e) => setsource_codes(e.target.value)}
              />
              Insert a valid URL.
            </div>
            <div className=" m-b-0_5">
              Whitepaper / Technical Documentation(optional)
            </div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={whitepaper}
                onChange={(e) => setwhitepapers(e.target.value)}
              />
              Insert a valid URL.
            </div>
            <div className=" m-b-0_5">
              Announcement (e.g. Bitcointalk)(optional)
            </div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={announcement}
                onChange={(e) => setannouncements(e.target.value)}
              />
              Insert a valid URL.
            </div>
            <div className=" m-b-0_5">
              Message Board 1 (e.g. Blog/Medium)(optional)
            </div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={message_board}
                onChange={(e) => setmessage_boards(e.target.value)}
              />
              Insert a valid URL.
            </div>
            <div className=" m-b-0_5">Twitter</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={twitter}
                onChange={(e) => settwitters(e.target.value)}
              />
              There is data validation for this field. Kindly ensure that your
              URL conforms to the following format. https://twitter.com/bitcoin
            </div>
            <div className=" m-b-0_5">Reddit (optional)</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={reddit}
                onChange={(e) => setreddits(e.target.value)}
              />
              Insert a valid URL for a subreddit.
            </div>
            <div className=" m-b-0_5">Facebook(optional)</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={facebook}
                onChange={(e) => setfacebooks(e.target.value)}
              />
              Insert a valid URL.
            </div>
            <div className=" m-b-0_5">Project introduction video(optional)</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={project_introduction}
                onChange={(e) => setproject_introductions(e.target.value)}
              />
              Share a link to an introduction video of the project (e.g.
              YouTube/Vimeo)
            </div>
            <div className=" m-b-0_5">
              Video Channel (e.g. YouTube)(optional)
            </div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={video_channel}
                onChange={(e) => setvideo_channels(e.target.value)}
              />
              Insert a valid URL.
            </div>
            <div className=" m-b-0_5">
              Chat 1 (e.g. Discord, Telegram, Slack, Weibo).
            </div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={chat}
                onChange={(e) => setchats(e.target.value)}
              />
              There is data validation for this field. Ex:
              https://t.me/ramzmarketcap
            </div>
            <div className=" m-b-0_5">LinkedIn(optional)</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={linkedin}
                onChange={(e) => setlinkedins(e.target.value)}
              />
              Insert a valid URL.
            </div>
            <div className=" m-b-0_5">
              Mobile App 1 (e.g. Google Play store, iTunes store)(optional)
            </div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={mobile_app}
                onChange={(e) => setmobile_apps(e.target.value)}
              />
              Insert a valid URL.
            </div>
            <div className=" m-b-0_5">
              Circulating Supply (# of coins that are circulating in the market
              and in the general public's hands.)
            </div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={circulating_supply}
                onChange={(e) => setcirculating_supplys(e.target.value)}
              />
              Insert EXACT number of units (e.g. 21000000).
            </div>
            <div className=" m-b-0_5">
              Total Supply (# of coins in existence right now minus any coins
              that have been verifiably burned.)(optional)
            </div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={total_supply}
                onChange={(e) => settotal_supplys(e.target.value)}
              />
              Insert EXACT number of units (e.g. 21000000).
            </div>
            <div className=" m-b-0_5">
              Max Supply (maximum # of coins that will ever exist in the
              lifetime of the asset)
            </div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={max_supply}
                onChange={(e) => setmax_supplys(e.target.value)}
              />
              Insert EXACT number of units (e.g. 21000000). If the max supply is
              infinite, type '0'
            </div>
            <div className=" m-b-0_5">ICO/IEO Start Date(optional)</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={ico_start_date}
                onChange={(e) => setico_start_dates(e.target.value)}
              />
            </div>
            <div className=" m-b-0_5">ICO/IEO End Date(optional)</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={ico_end_date}
                onChange={(e) => setico_end_dates(e.target.value)}
              />
            </div>{" "}
            <div className=" m-b-0_5">ICO/IEO/Launch Price(optional)</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={ico_launch_price}
                onChange={(e) => setico_launch_prices(e.target.value)}
              />
              Insert USD Price
            </div>
            <div className=" m-b-0_5">Project api name</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={project_api_name}
                onChange={(e) => setproject_api_names(e.target.value)}
              />
              MUST be in this EXACT same text which is used in your json file -
              e.g. bitcoin
              <img src={project_api} className="m-t-0_5 w-100" />
            </div>
            <div className=" m-b-0_5">API for live price</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={api_live_price}
                onChange={(e) => setapi_live_prices(e.target.value)}
              />
              MUST be in this EXACT format -
              ("https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd&include_market_cap=true&include_24hr_vol=true&include_24hr_change=true&include_last_updated_at=true")
            </div>
            <div className=" m-b-0_5">Historical graph api</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={graph_historical_api}
                onChange={(e) => setgraph_historical_apis(e.target.value)}
              />
              MUST be in this EXACT format -
              ("https://api.coingecko.com/api/v3/coins/ethereum/market_chart?vs_currency=usd&days=4")
            </div>
            <div className=" m-b-0_5">
              List of CMC-supported exchanges(optional)
            </div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <input
                type="text"
                className="w-100 b-1 b-r-5 m-b-0_5  pa-0_5"
                value={cmc_supported_exchange}
                onChange={(e) => setcmc_supported_exchanges(e.target.value)}
              />
              Enter NA if not applicable. Provide (i) screenshots (e.g.
              imgur/gyazo) and (ii) URLs that link directly to the
              market(s)/pair(s). Separate each URL with a comma. See example -
              https://www.binance.com/en/trade/BTC_USDT
            </div>
            {/* <div className=" m-b-0_5">Attachments(optional)</div>
            <div className="m-b-2 f-s-0_8 ">
              {" "}
              <div className="d-f a-i-c w-100 ">
                <div className="w-100 ">
                  {" "}
                  <input
                    type="file"
                    className="w-100 b-1 b-r-5 m-b-0_5 t-a-c  pa-0_5"
                    value={attachment}
                  />{" "}
                </div>
                <div className="w-s-n-w">
                  {" "}
                  <button
                    className="b-c-t c-w w-s-n-w bg_b_clr pa-0_5 b-n b-r-5 h-100"
                    // onClick={handleFileUpload}
                  >
                    Upload File
                  </button>
                </div> 
              </div>
            </div> */}
            <div className="">
              <button
                className="submit b-n bg_b_clr b-c-t c-w p-x-2 p-y-0_5 b-r-5"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
      <Footer />
      <Toaster position="top-center" reverseOrder={true} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default Create_form;
