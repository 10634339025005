import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";

function Aum_Chart({ api }) {
  const [btc_price, setbtc_prices] = useState();

  useEffect(() => {
    bitcoin_price_fetch();
  }, []);

  const [chartData, setChartData] = useState([]);
  const [h_24, seth_24s] = useState(1);
  const [var_price, setvar_prices] = useState("prices");
  const [price_name, setprice_names] = useState("Volume");

  const chartOptions = {
    chart: {
      id: "zoomable-chart",
      type: "area",
      height: 350,
      zoom: {
        enabled: true,
      },
      toolbar: {
        autoSelected: "zoom",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return `$${value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
        },
      },
      opposite: true,
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy",
      },
    },
  };

  useEffect(() => {
    axios
      .get(`${api}${h_24}`)
      .then((response) => {
        const data = response.data;

        // Process the fetched data and format it for the chart
        const formattedData = data.map(([timestamp, value]) => ({
          x: new Date(timestamp).getTime(),
          y: parseFloat(value) * parseFloat(btc_price), // Multiply value by btc_price
        }));

        setChartData([{ name: price_name, data: formattedData }]);
      })
      .catch((error) => console.log(error));
  }, [h_24, api, price_name, btc_price]);

  const bitcoin_price_fetch = async () => {
    try {
      const response = await axios.get(
        `https://pro-api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd&precision=4&x_cg_pro_api_key=CG-VTWbFPyR2KdGKX9LPTiPFBWW`,
      );
      console.log("response12315");
      setbtc_prices(response.data.bitcoin.usd);
    } catch (error) {}
  };

  return (
    <div>
      <div className="m-q-d-f flex-wrap a-i-c c-p">
        <div className="m-q-m-r-1 m-b-1 b-r-5">
          <div className="m-r-1 bg_b_clr pa-1 p-x-2 c-w b-r-5">Volume</div>
        </div>
        <div className="m-q-m-r-1 m-b-1 b-r-5">
          <div className="d-f a-i-c j-c-s-e pa-5rem bg_g_clr b-r-5">
            <div
              className={`${
                h_24 === 1 ? "t-a-c bg_d_b_clr b-r-5" : ""
              } m-r-0_5 pa-5rem t-a-c`}
              onClick={() => seth_24s(1)}
            >
              1D
            </div>
            <div
              className={`${
                h_24 === 7 ? "t-a-c bg_d_b_clr b-r-5" : ""
              } m-r-0_5 pa-5rem t-a-c`}
              onClick={() => seth_24s(7)}
            >
              7D
            </div>
            <div
              className={`${
                h_24 === 30 ? "t-a-c bg_d_b_clr b-r-5" : ""
              } m-r-0_5 pa-5rem t-a-c`}
              onClick={() => seth_24s(30)}
            >
              1M
            </div>
            <div
              className={`${
                h_24 === 365 ? "t-a-c bg_d_b_clr b-r-5" : ""
              } m-r-0_5 pa-5rem t-a-c`}
              onClick={() => seth_24s(365)}
            >
              1Y
            </div>
            <div
              className={`${
                h_24 === 1825 ? "t-a-c bg_d_b_clr b-r-5" : ""
              } m-r-0_5 pa-5rem t-a-c`}
              onClick={() => seth_24s(1825)}
            >
              5Y
            </div>
            <div
              className={`${
                h_24 === "max" ? "t-a-c bg_d_b_clr b-r-5" : ""
              } pa-5rem t-a-c`}
              onClick={() => seth_24s("max")}
            >
              ALL
            </div>
          </div>
        </div>
      </div>
      <div className="b-1 b_clr_gray w-100 p-x-1 p-t-1 p-b-2_5 b-r-5">
        <div className="chart">
          <ReactApexChart
            options={chartOptions}
            series={chartData}
            type="area"
            height={350}
          />
        </div>
      </div>
    </div>
  );
}

export default Aum_Chart;
