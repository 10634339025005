import { useQuery } from "@tanstack/react-query";

const getGCEX24Volume = async () => {
  const response = await fetch(
    "https://api.gcex.lt/api/v1/exchange-24hr-volume",
    {
      headers: {
        "ngrok-skip-browser-warning": "true",
      },
    },
  );
  return await response.json();
};

export const useGetGCEX24Volume = () => {
  return useQuery({
    queryKey: ["gcex24-volume"],
    queryFn: getGCEX24Volume,
    refetchInterval: 1000 * 30, // pulling interval 30 seconds
  });
};
