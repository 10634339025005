import React, { useEffect, useState } from "react";
import axios from "axios";
import Super_admin_navbar from "./Super_admin_navbar";
import { Link } from "react-router-dom";
import { useStoreState } from "easy-peasy";
import toast, { Toaster } from "react-hot-toast";
import { RiArrowGoBackFill } from "react-icons/ri";

const notify = (msg) => toast.success(msg);
const notifyError = (msg) => toast.error(msg);
const notifyMessage = (msg) =>
  toast(msg, {
    duration: 10000,
  });

function Super_admin({ url }) {
  const [email, setemails] = useState("");
  const [password, setpasswords] = useState("");
  const [otp_box, setotp_boxs] = useState(false);
  const [token, setToken] = useState("");
  const [protectedData, setProtectedData] = useState("");
  const [otp, setotps] = useState("");
  const [isverify, setisVerify] = useState(false);
  const [new_email, setnew_emails] = useState();
  const [confirm_new_email, setconfirm_new_emails] = useState();
  const [verify_email, setverify_emails] = useState();

  const [randomNumber, setRandomNumber] = useState(null);
  const [random_no, setrandom_nos] = useState();

  const generateRandomNumber = () => {
    const newRandomNumber = Math.floor(100000 + Math.random() * 900000); // Generate a random 6-digit number
    setRandomNumber(newRandomNumber);
  };

  useEffect(() => {
    generateRandomNumber();
  }, [email]);

  // const sendOtp = async () => {
  //   await generateRandomNumber();
  //   await sendEmail();
  // };

  // const api_key = useStoreState((state) => state.email_api_key);

  const sendEmail = async () => {
    const apiKey =
      "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";
    const endpoint = "https://api.sendinblue.com/v3/smtp/email";

    try {
      const response = await axios.post(
        endpoint,
        {
          sender: { email: "sender@gmail.com" },
          to: [{ email: `${email}` }],
          subject: "otp",
          textContent: `Hi, Thank you for choosing Ramz Market Place. Use the following OTP ${randomNumber} to complete your verification process. `,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "api-key": apiKey,
          },
        },
      );

      if (response.status === 201) {
        setrandom_nos(randomNumber);
        notify("Otp Sent");
        console.log("abc");
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  const otp_check = () => {
    if (random_no == otp) {
      setisVerify(true);
      notify("Otp verified Successful");
    }
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();

    if (new_email !== confirm_new_email) {
      console.log("New email do not match.");
      return;
    }

    try {
      const response = await axios.post(`${url}/api/update-email`, {
        email: email,
        new_email: new_email,
      });
      if (response.data.message == "Email updated successfully") {
        notify("Email changed Successful");
        window.location.href = `/super_admin`;
      }
      console.log(response.data.message);
    } catch (error) {
      console.log(error);
    }
  };

  const check_email = async (e) => {
    // e.preventDefault();
    try {
      const response = await axios.post(`${url}/check_email`, {
        email: email,
      });

      if (response.data == "email is verified") {
        sendEmail();
        setotp_boxs(true);
      }
      //       else{
      // notify("Wrong Email")
      //       }
    } catch (error) {
      console.error("Error:", error);
      notifyError("Email is not registered");
    }
  };

  // console.log(random_no, otp);
  return (
    <div>
      <div className="container-kws p-t-7">
        {!isverify ? (
          <>
            <div className="d-f a-i-c j-c-s-b">
              <a href="super_admin">
                {" "}
                <RiArrowGoBackFill />
              </a>

              <div className="m-y-3  f-s-2 t-a-c f-w-600">Change Email</div>
              <div className=""></div>
            </div>
            {otp_box ? (
              <div className="row m-y-1">
                <div className="col-4 f-s-1_25 f-w-600">Otp</div>
                <div className="col-8">
                  <input
                    type="text"
                    className="w-100 b-1 b-r-5 pa-0_5"
                    placeholder="enter otp ...."
                    value={otp}
                    onChange={(e) => setotps(e.target.value)}
                  />
                </div>
              </div>
            ) : (
              <div className="row m-y-1">
                <div className="col-4 f-s-1_25 f-w-600">Email </div>
                <div className="col-8">
                  <input
                    type="email"
                    className="w-100 b-1 b-r-5 pa-0_5"
                    placeholder="enter email ...."
                    value={email}
                    onChange={(e) => setemails(e.target.value)}
                  />
                </div>
              </div>
            )}

            <div className=" t-a-c m-t-3">
              {otp_box ? (
                <button
                  className="f-w-600 f-s-1_5 b-c-t c-w b-1 b-r-20 p-x-3 p-y-0_5"
                  // type="submit"
                  onClick={() => otp_check()}
                >
                  Submit Otp
                </button>
              ) : (
                <button
                  className="f-w-600 f-s-1_5 b-c-t c-w b-1 b-r-20 p-x-3 p-y-0_5"
                  // type="submit"
                  // onClick={() => {
                  //   sendEmail(), setotp_boxs(true);
                  // }}
                  onClick={() => check_email()}
                >
                  Get Otp
                </button>
              )}
            </div>
          </>
        ) : (
          ""
        )}

        {isverify ? (
          <>
            <div className="m-y-3 f-s-2 t-a-c f-w-600">Change Email</div>
            <div className="row m-y-1">
              <div className="col-4 f-s-1_25 f-w-600">New Email</div>
              <div className="col-8">
                <input
                  type="email"
                  className="w-100 b-1 b-r-5 pa-0_5"
                  placeholder="enter new email"
                  value={new_email}
                  onChange={(e) => setnew_emails(e.target.value)}
                />
              </div>
            </div>
            <div className="row m-y-1">
              <div className="col-4 f-s-1_25 f-w-600">Confirm New Email</div>
              <div className="col-8">
                <input
                  type="email"
                  className="w-100 b-1 b-r-5 pa-0_5"
                  placeholder="enter confirm new email"
                  value={confirm_new_email}
                  onChange={(e) => setconfirm_new_emails(e.target.value)}
                />
              </div>
            </div>
            <div className="m-t-2">
              <button
                className="f-w-600 f-s-1_5 b-c-t c-w b-1 b-r-20 p-x-3 d-b m-a p-y-0_5"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Submit{" "}
              </button>
            </div>
          </>
        ) : (
          ""
        )}
      </div>{" "}
      <Toaster position="top-center" reverseOrder={true} />
    </div>
  );
}

export default Super_admin;
