// src/components/Sitemap.js
import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Sitemap = ({ url, Dissconnect, Metamask, WalletC }) => {
  // Define your sitemap links here
  const sitemapLinks = [
    { title: "Home", url: "/" },
    { title: "Disclaimer", url: "/disclaimer" },
    { title: "Privacy", url: "/privacy" },
    { title: "Exchange", url: "/exchanges" },
    { title: "Terms", url: "/terms" },
    { title: "ShariaCoin", url: "/ShariaCoin" },

    // Add more links as needed
  ];

  return (
    <div>
      <Navbar Dissconnect={Dissconnect} Metamask={Metamask} WalletC={WalletC} />
      <div className="container-kws min-h-90vh">
        <h1>Sitemap</h1>
        <ul className="">
          {sitemapLinks.map((link, index) => (
            <li key={index} className="m-y-0_5">
              <a href={link.url}>{link.title}</a>
            </li>
          ))}
        </ul>
      </div>
      <Footer />
    </div>
  );
};

export default Sitemap;
