import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";

function Terms_of_service({ url, Dissconnect, Metamask, WalletC }) {
  return (
    <>
      <Navbar Dissconnect={Dissconnect} Metamask={Metamask} WalletC={WalletC} />
      <div className="container-kws  ">
        <div class="c13 c25 doc-content ">
          <div className="f-s-2 f-w-600 m-b-2">Terms of Service</div>
          <p class="c0">
            <span class="c7">RamzMarketCap.com Terms of Use</span>
          </p>
          <p class="c0">
            <span class="c1">Last updated: 27 September 2023</span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c7">Introduction</span>
          </p>
          <p class="c0 c5">
            <span class="c7"></span>
          </p>
          <p class="c0">
            <span>
              These RamzMarketCap.com (RMC) Terms of Use (the "Terms") govern
              your access to and use of our website (the "Platform"), and
              associated content, features, software and applications in the
              Platform (collectively, the "Service"). These Terms also include
              our Privacy Policy, which is located at{" "}
            </span>
            <span class="c6">
              <a
                class="c23"
                href="https://www.google.com/url?q=https://ramzmarketcap.com/privacy/&amp;sa=D&amp;source=editors&amp;ust=1695950451413901&amp;usg=AOvVaw2HvMABdXoDnBR5hsqkMP7S"
              >
                https://ramzmarketcap.com/privacy/
              </a>
            </span>
            <span class="c1">&nbsp;.</span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span>
              The Platform and the Service is administered and maintained by{" "}
            </span>
            <span class="c18">Tijarah Holding Sdh. Bhd.</span>
            <span class="c1">
              , a Labuan offshore limited company. The terms "we", "us" and
              "our'' refers to our product/platform RMC. The term "you" refers
              to the person visiting the Platform.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Please read these Terms carefully. They contain important
              information regarding your legal rights, including limitations on
              RMC's and certain third parties' liability, disclaimers of
              warranties and a submission to jurisdiction. Each time you access
              the Service by any means (for example, through the Internet or on
              a mobile device), you irrevocably agree to comply with the version
              of the Terms posted on the Platform at the time you access the
              Platform. You may not use the Platform or the Service if you do
              not agree to these Terms.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c7">Eligibility </span>
          </p>
          <p class="c0 c5">
            <span class="c7"></span>
          </p>
          <p class="c0">
            <span class="c1">
              To access or use the Service, you must be at least of legal age in
              your respective jurisdiction. The Service is strictly for your
              personal, non-commercial use unless you enter into a separate
              agreement with us for your commercial use. You must not use the
              Service if we have terminated any account of yours or banned you
              from use of the Platform or the Service.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c7">Account</span>
          </p>
          <p class="c0 c5">
            <span class="c7"></span>
          </p>
          <p class="c0">
            <span class="c1">
              You would need to have an account with RamzMarketCap ("Account")
              in order to use some parts of the Service. When you create an
              Account, you represent and warrant that you are at least of legal
              age in your respective jurisdiction, you are capable of entering
              into and performing legally binding contracts under applicable
              law, and that all information which you provide is accurate,
              up-to-date, truthful and complete.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              You are solely responsible for all activities under your Account.
              We will not be responsible in any way if your password and/or
              Account are misappropriated or used by a third party. You
              therefore agree to keep your password secure and keep your account
              information up-to-date at all times.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Unless expressly permitted by RamzMarketCap and subject to the
              Terms and any other additional terms as RamzMarketCap solely and
              absolutely determines, you shall not set up multiple Accounts. You
              shall not lend, transfer or sell your Account or Account
              information to another party and must not use another user's
              Account without their written permission.
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              RamzMarketCap may occasionally contact you on the email address
              provided in your Account registration. You will not be able to opt
              out from such communications, and you shall take the
              responsibility to ensure that your email address is up-to-date. If
              you missed any communications due to an inaccurate, outdated, or
              incomplete email address, RamzMarketCap will not be liable for any
              losses or damages caused by you missing the communication.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c7">Licence</span>
          </p>
          <p class="c0 c5">
            <span class="c7"></span>
          </p>
          <p class="c0">
            <span class="c1">
              You must only use the Service as expressly permitted by these
              Terms. All rights not expressly granted to you are reserved by
              RMC, its third-party providers and other respective owners, if
              any.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              RMC provides content through the Service that is copyrighted
              and/or trademarked work of RMC or RMC's third-party licensors and
              suppliers (collectively, the "Content"). For clarity, Content
              shall include all such content accessed by you at any time. You
              acknowledge that the Service has been developed, compiled,
              prepared, revised, selected and arranged by RMC and such third
              parties through the application of methods and standards of
              judgment developed and applied through the expenditure of
              substantial time, effort and money and constitutes valuable
              intellectual property of RMC and such others. Accordingly, you
              shall protect the proprietary rights of RMC and all others having
              rights in the Service during and after the term of these Terms and
              comply with all written requests made by RMC to protect its and
              others' contractual, statutory, and common law rights in the
              Service.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Subject to these Terms, and your compliance with these Terms, RMC
              hereby grants you a limited, personal, non-exclusive,
              non-sub-licensable and non-transferable license to use the Content
              and to use this Service, in each case solely for your personal
              use. You agree not to use the Service or any of the Content for
              any commercial purpose. Except for the foregoing license, you have
              no other rights to the Service or any Content, and you may not
              modify, edit, copy, distribute, reproduce, publish, display,
              perform, license, sell, rent, lease, loan, create derivative works
              of, create any index, reverse engineer, alter, enhance, provide
              access to or in any way exploit the Service or the Content in any
              manner.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              If you breach any of these Terms, the above license will terminate
              automatically.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c7">Prohibited Activities</span>
          </p>
          <p class="c0 c5">
            <span class="c7"></span>
          </p>
          <p class="c0">
            <span class="c1">You agree that you will not:</span>
          </p>
          <ul class="c17 lst-kix_u7wsdv6dawrd-0 start">
            <li class="c0 c4 li-bullet-0">
              <span class="c1">
                Copy, modify or create derivative works of the Service or any
                Content;
              </span>
            </li>
            <li class="c0 c4 li-bullet-0">
              <span class="c1">
                Copy, manipulate or aggregate any Content (including data) for
                the purpose of making it available to any third party;
              </span>
            </li>
            <li class="c0 c4 li-bullet-0">
              <span class="c1">
                Trade, sell, rent, loan, lease or license any Content or access
                to the Service, whether commercially or free of charge;
              </span>
            </li>
            <li class="c0 c4 li-bullet-0">
              <span>
                Use or introduce to the Service any data mining, crawling,
                "scraping", robot or similar automated or data gathering or
                extraction method, or manually access, acquire, monitor or copy
                any portion of the Service, or download or store Content (unless
                expressly authorized by RMC). Certain data and other information
                within the Service is available by subscription, or for a fee,
                at{" "}
              </span>
              <span class="c6">
                <a
                  class="c23"
                  href="https://www.google.com/url?q=https://ramzmarketcap.com/api/&amp;sa=D&amp;source=editors&amp;ust=1695950451416016&amp;usg=AOvVaw2mTEw6LhetQUqIcWe0CnDL"
                >
                  https://ramzmarketcap.com/api/
                </a>
              </span>
              <span class="c1">&nbsp;(under active development);</span>
            </li>
            <li class="c0 c4 li-bullet-0">
              <span class="c1">
                Make excessive requests for information or take any action that
                interferes with, disrupts or imposes an undue burden on the
                Service or any server or network connected to the Service or
                negatively affects the quality or availability of any Content,
                or speed or functionality of the Service;
              </span>
            </li>
            <li class="c0 c4 li-bullet-0">
              <span class="c1">
                Introduce a virus, Trojan horse, worm, time bomb or other
                malware to the Service or the Platform, or use any device,
                software or routine to bypass any software or hardware that
                prohibits volume requests for information;
              </span>
            </li>
            <li class="c0 c4 li-bullet-0">
              <span class="c1">
                Violate, bypass or circumvent any security measure intended to
                limit or prevent access to the Platform, Content or Service; or
              </span>
            </li>
            <li class="c0 c4 li-bullet-0">
              <span class="c1">
                Otherwise attempt to gain unauthorized access to the Service,
                any Content or to any computer systems or networks connected to
                the Service or any RMC server, whether through hacking, password
                mining, unauthorized use of another's password/credentials or
                any other means;
              </span>
            </li>
            <li class="c0 c4 li-bullet-0">
              <span class="c1">
                Restrict, inhibit or interfere with use of the Service by any
                other user (including by hacking or defacing the Platform);
              </span>
            </li>
            <li class="c0 c4 li-bullet-0">
              <span class="c1">
                Introduce or otherwise distribute through the Platform any
                computer program that damages, interferes with, intercepts,
                collects, releases or discloses any system, data or personal
                information of ours or any third party;
              </span>
            </li>
            <li class="c0 c4 li-bullet-0">
              <span class="c1">
                Make use of any of our trademarks, service marks, trade names or
                logos or those of any third party displayed on the Platform;
              </span>
            </li>
            <li class="c0 c4 li-bullet-0">
              <span class="c1">
                Modify or remove any copyright or other proprietary notice in
                the Content;
              </span>
            </li>
            <li class="c0 c4 li-bullet-0">
              <span class="c1">
                Use the Website, Content or Service for or in connection with
                any activity that (i) violates any applicable law, statute,
                ordinance or regulation, including without limitation, the U.S.
                Department of the Treasury's Office of Foreign Assets Control
                (OFAC), or (ii) involves proceeds of any unlawful or illegal
                activity.
              </span>
            </li>
          </ul>
          <p class="c0 c3">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Additionally, you acknowledge and agree that you (and not RMC) are
              solely responsible for obtaining and maintaining all
              telecommunications, broadband, and computer hardware, equipment,
              software and services needed for you to access and use the
              Service, and paying all charges related thereto.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c7">Ownership and Intellectual Property</span>
          </p>
          <p class="c0 c5">
            <span class="c7"></span>
          </p>
          <p class="c0">
            <span>
              Subject to these Terms, you may view, print and make copies of
              Content for your own personal use. You shall not, copy, reproduce,
              download, "screen scrape", store, transmit, broadcast, publish,
              modify, create a derivative work from, display, perform,
              distribute, redistribute, sell, license, rent, lease or otherwise
              use, transfer (either in printed, electronic or other format) or
              exploit any Content, in whole or in part, in any way that does not
              comply with these Terms without our prior written permission. To
              request permission to use any Content other than as expressly
              permitted in these Terms, please contact{" "}
            </span>
            <span class="c6">
              <a class="c23" href="mailto:legal@ramzmarketcap.com">
                legal@ramzmarketcap.com
              </a>
            </span>
            <span class="c1">.</span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              As between RMC and you, all Content is owned or controlled by RMC.
              RMC, its logo, and, except as noted below, all other product or
              service names or slogans displayed on the Service are registered
              and/or common law trademarks of RMC or its suppliers or licensors
              and may not be copied, imitated, or used, in whole or in part,
              without the prior written permission of RMC or the applicable
              trademark holder. In addition, the look and feel of the Service,
              including all page headers, custom graphics, button icons, and
              scripts, is the service mark, trademark, and/or trade dress of RMC
              and may not be copied, imitated, or used, in whole or in part,
              without the prior written permission of RMC. All other trademarks,
              registered trademarks, product names, and company names or logos
              mentioned in the Service are the property of their respective
              owners. Reference to any products, services, processes, or other
              information, by trade name, trademark, manufacturer, supplier, or
              otherwise does not constitute or imply endorsement, sponsorship,
              or recommendation thereof by RMC.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Nothing contained in these Terms grants you any interest in any of
              RMC's or any third-party's intellectual property.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              The use or misuse of RMC's trademarks or other intellectual
              property, except as expressly permitted by these Terms, is
              prohibited. You shall promptly notify RMC at
              legal@ramzmarketcap.com if you know or suspect that any of RMC's
              or its providers' intellectual property rights has been violated
              or infringed.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              "RamzMarketCap" and all related logos, trademarks, service marks
              and trade names are solely the property of RMC. The absence of a
              name, logo or other mark herein does not constitute a waiver of
              any and all intellectual property rights that RMC has established.
              Other trademarks, names or logos used on the Website are property
              of their respective owners. You are not authorized to use any of
              the foregoing.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              You acknowledge that RMC and/or its providers own the copyright in
              and to all Content under the applicable laws (including copyright
              laws and other intellectual property laws), and have reserved all
              rights in and to such Content.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c7">Linking to the Service from Your Website</span>
          </p>
          <p class="c0 c5">
            <span class="c7"></span>
          </p>
          <p class="c0">
            <span class="c1">
              You may place one or more links to the Service (collectively, the
              "Link") on your own website ("Your Site"), provided that:
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <ul class="c17 lst-kix_vepagwdixln4-0 start">
            <li class="c0 c4 li-bullet-0">
              <span class="c1">
                The Link shall display only the following text: "RamzMarketCap"
                or "RamzMarketCap.com" or "Link to RamzMarketCap.com";
              </span>
            </li>
            <li class="c0 c4 li-bullet-0">
              <span class="c1">
                Your Site shall not contain any content that is unlawful,
                threatening, abusive, libelous, defamatory or otherwise
                inappropriate, as determined by us in our sole and absolute
                discretion;
              </span>
            </li>
            <li class="c0 c4 li-bullet-0">
              <span class="c1">
                The look and feel of all content that accompanies the Link or is
                on the same page as the Link (for example, the entire article in
                which the Link appears, even if it is not all on the same page
                as the Link) shall not otherwise be of a nature that may damage
                or dilute the goodwill associated with RMC's name, reputation or
                any of its trademarks, trade names or service marks, as
                determined by RMC in its sole and absolute discretion; and
              </span>
            </li>
            <li class="c0 c4 li-bullet-0">
              <span class="c1">
                No content on Your Site shall contain any information that, in
                our sole and absolute discretion, may create the false
                impression that you, Your Site or any other website, service,
                person or entity is associated with, sponsored by or otherwise
                endorsed by RMC, or that any activity engaged in by you or
                anyone else has been approved by RMC.
              </span>
            </li>
          </ul>
          <p class="c0 c3">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              We may revoke our consent to a Link at any time in our sole and
              absolute discretion, without prior notice. If we notify you that
              you may no longer link to the Service, or to a page or document,
              you must promptly (and, in any event, within three (3) calendar
              days from the date of our notice) remove all affected Links from
              Your Site.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c7">
              Community Generated Content; Grant Of Right To RamzMarketCap
            </span>
          </p>
          <p class="c0 c5">
            <span class="c7"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Subject to the provisions of these Terms, the Service allows you
              to post and share texts, images, videos, predictions, or other
              materials. You are solely responsible for the content you provide,
              including but not limited to be legally compliant with applicable
              laws, rules and regulations.
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              You acknowledge and agree that by using the Service, you might be
              exposed to content that is harmful, disrespectful, irritating,
              offensive, inaccurate, misleading, or in any form inappropriate.
              The person or party (and not RamzMarketCap) is solely responsible
              for any and all community-generated content posted on the Platform
              by such person or party.{" "}
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              RamzMarketCap may not prescreen, review or moderate all
              community-generated contents posted and shall not be liable for
              any of such content. Without limiting the foregoing, RamzMarketCap
              reserves the right in its sole and absolute discretion to remove
              any community-generated content, without liability or the
              obligation to offer a refund, in any of the following events:
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <ul class="c17 lst-kix_prhyygtwpz9p-0 start">
            <li class="c0 c4 li-bullet-0">
              <span class="c1">
                If the community-generated content is in breach of these Terms
                and/or the Community Rules.
              </span>
            </li>
            <li class="c0 c4 li-bullet-0">
              <span class="c1">
                If we have received a complaint or notice of infringement in
                respect of the community-generated content.
              </span>
            </li>
            <li class="c0 c4 li-bullet-0">
              <span class="c1">
                If the community-generated content is otherwise objectionable.
              </span>
            </li>
          </ul>
          <p class="c0 c3">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Any community generated content posted shall not be considered as
              financial advice, and you fully understand and agree that you
              shall solely bear the risks of using the information from such
              content.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span>
              Before posting any community-generated content, you acknowledge
              and agree that you own or are licensed to the intellectual
              property rights in such content. If you know or suspect that other
              community-generated content have infringed your intellectual
              property rights, please report to us at contact{" "}
            </span>
            <span class="c6">
              <a class="c23" href="mailto:legal@ramzmarketcap.com">
                legal@ramzmarketcap.com
              </a>
            </span>
            <span class="c1">.</span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              You retain ownership rights in the content you post on the
              Platform, but you grant RamzMarketCap a worldwide, fully-paid,
              royalty-free, sub-licensable, and transferable license to host,
              store, use, display, reproduce, modify, adapt, edit, publish and
              distribute such content (subject to our current Privacy Policy)
              for the purpose of operating, developing, providing, promoting,
              and improving the Service and to research and develop new products
              and services. We are not obliged to make any compensation to you
              with respect to the content that you submit or post on the
              Platform. You acknowledge and agree that your use of the Service
              is sufficient compensation for the content you submit or post on
              the Platform and the grant of rights herein.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c7">Feedback</span>
          </p>
          <p class="c0 c5">
            <span class="c7"></span>
          </p>
          <p class="c0">
            <span>
              This section does not apply to information supplied by you to the
              Service that can identify you personally. RMC believes in
              protecting your privacy. Please visit{" "}
            </span>
            <span class="c6">
              <a
                class="c23"
                href="https://www.google.com/url?q=https://ramzmarketcap.com/privacy/&amp;sa=D&amp;source=editors&amp;ust=1695950451419638&amp;usg=AOvVaw1QSd5PxSjyuUd9i_am1aSK"
              >
                https://ramzmarketcap.com/privacy/
              </a>
            </span>
            <span class="c1">
              &nbsp;to review our current Privacy Policy, which also governs
              your use of the Service, to understand our practices.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              If you send or transmit any communication, including but not
              limited to feedback, questions, comments or suggestions to RMC
              (collectively, "Feedback"), all such Feedback is, and will be
              treated as non-confidential and non-proprietary. All Feedback is
              to be submitted via the Platform only. You hereby assign to RMC
              all right, title, and interest in, and RMC is free to use, without
              any attribution or compensation to you, any ideas, concepts,
              know-how or techniques or other intellectual property and
              proprietary rights contained in the Feedback, whether or not
              patentable, for any purpose whatsoever, including but not limited
              to enhancing the Service, or otherwise developing, manufacturing,
              licensing, marketing and selling products and services based on or
              containing such Feedback. You also understand and agree that RMC
              is not obligated to consider, accept, use, display, reproduce, or
              distribute any such ideas, know-how, concepts, or techniques
              contained in the Feedback, and you have no right to compel such
              use, display, reproduction, or distribution. RMC may use
              aggregated and statistical data derived from usage of the
              Platform.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c7">Disclaimers and Limitation of Liability</span>
          </p>
          <p class="c0 c5">
            <span class="c7"></span>
          </p>
          <p class="c0">
            <span class="c1">
              THE CONTENT ON THE SERVICE AND THE PLATFORM IS STRICTLY FOR
              INFORMATIONAL PURPOSES ONLY. NOTHING ON OR IN THE SERVICE SHALL
              CONSTITUTE OR BE CONSTRUED AS AN OFFERING OF ANY CURRENCY,
              SECURITY OR ANY FINANCIAL INSTRUMENT OR AS INVESTMENT ADVICE OR
              INVESTMENT RECOMMENDATIONS (SUCH AS RECOMMENDATIONS AS TO WHETHER
              TO PURCHASE A CURRENCY, SECURITY OR INSTRUMENT) BY RMC OR A
              RECOMMENDATION AS TO AN INVESTMENT STRATEGY BY RMC. CONTENT ON
              THIS SERVICE SHOULD NOT BE CONSIDERED AS INFORMATION SUFFICIENT
              UPON WHICH TO BASE AN INVESTMENT STRATEGY. NO CONTENT ON THE
              SERVICE IS TAILORED TO THE SPECIFIC NEEDS OF ANY INDIVIDUAL,
              ENTITY OR GROUP OF INDIVIDUALS. RMC EXPRESSES NO OPINION AS TO THE
              FUTURE OR EXPECTED VALUE OF ANY CURRENCY, SECURITY OR OTHER
              INTEREST. RMC DOES NOT EXPLICITLY OR IMPLICITLY RECOMMEND OR
              SUGGEST ANY INVESTMENT STRATEGY OF ANY KIND. CONTENT ON THE
              SERVICE MAY NOT BE USED AS A BASIS FOR ANY FINANCIAL PRODUCT OR
              OTHER PRODUCT WITHOUT THE EXPRESS PRIOR WRITTEN CONSENT OF RMC.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              The Content provided on the Service is submitted to RMC by
              unrelated third-party providers. RMC does not review all Content
              for accuracy, does not review Content for completeness or
              reliability, and does not warrant or guarantee the accuracy,
              completeness, reliability or any other aspect of any Content.
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              THE SERVICE AND THE CONTENT PUBLISHED WITHIN THE SERVICE MAY
              INCLUDE INACCURACIES OR ERRORS. RMC DOES NOT GUARANTEE THE
              ACCURACY, COMPLETENESS, TIMELINESS, SECURITY, AVAILABILITY OR
              INTEGRITY OF THE SERVICE OR ANY CONTENT, AND DISCLAIMS ALL
              LIABILITY FOR ANY ERRORS OR OTHER INACCURACIES RELATING TO THE
              SERVICE OR THE CONTENT. RMC MAKES NO REPRESENTATIONS ABOUT THE
              SUITABILITY OF THE SERVICE OR ANY CONTENT, OR THAT THE SERVICE OR
              CONTENT WILL BE UNINTERRUPTED OR OPERATE IN COMBINATION WITH ANY
              SOFTWARE, SERVICE, SYSTEM OR OTHER DATA OR INFORMATION. THE
              SERVICE AND ALL CONTENT ARE PROVIDED "AS IS'' WITHOUT WARRANTY OF
              ANY KIND. RMC DISCLAIMS ALL WARRANTIES AND CONDITIONS THAT THIS
              SERVICE, ITS SERVERS, OR ANY EMAIL OR ELECTRONIC MESSAGE SENT FROM
              RMC, ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. RMC HEREBY
              DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH REGARD TO THE SERVICE
              AND THE CONTENT, INCLUDING ALL IMPLIED WARRANTIES AND CONDITIONS
              OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
              NON INFRINGEMENT.
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, RMC ASSUMES NO
              LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR
              INACCURACIES OF ANY CONTENT; (II) PROPERTY DAMAGE, OF ANY NATURE
              WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR USE OF THE SERVICE;
              (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SERVERS AND/OR ANY
              AND ALL INFORMATION STORED THEREIN; (IV) ANY INTERRUPTION OR
              CESSATION OF TRANSMISSION TO OR FROM THE SERVICE; (V) ANY BUGS,
              VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR
              THROUGH THE SERVICE BY ANY THIRD PARTY; (VI) ANY ERRORS OR
              OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE INCURRED AS A
              RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE
              MADE AVAILABLE THROUGH THE SERVICE; AND/OR (VII) THE DEFAMATORY,
              OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY.
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              IN NO EVENT SHALL RMC (AND ITS RESPECTIVE OFFICERS, DIRECTORS,
              EMPLOYEES, MEMBERS, AGENTS, AND AFFILIATES) BE LIABLE FOR ANY
              INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES
              OR DAMAGES FOR LOST PROFITS, ARISING OUT OF, OR IN ANY WAY
              CONNECTED WITH, YOUR ACCESS TO OR USE OF THE SERVICE OR WITH THE
              DELAY OR INABILITY TO ACCESS, DISPLAY, OR USE THE SERVICE; ANY
              COMPUTER VIRUSES, INFORMATION, SOFTWARE, LINKED SERVICES,
              PRODUCTS, AND SERVICES OBTAINED THROUGH THE SERVICE; OR OTHERWISE
              ARISING OUT OF THE ACCESS TO OR USE OF THE SERVICE WHETHER BASED
              ON A THEORY OF NEGLIGENCE, CONTRACT, TORT, STRICT LIABILITY, OR
              OTHERWISE, AND EVEN IF RMC HAS BEEN ADVISED OF THE POSSIBILITY OF
              SUCH DAMAGES.
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              IN NO EVENT SHALL RMC (AND ITS RESPECTIVE OFFICERS, DIRECTORS,
              EMPLOYEES, MEMBERS, AGENTS, AND AFFILIATES) BE LIABLE TO YOU FOR
              ANY CLAIMS, PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES
              OR COSTS IN EXCESS OF US$100.00. THE LIMITATION OF LIABILITY
              REFLECTS THE ALLOCATION OF RISK BETWEEN THE PARTIES. THE
              LIMITATIONS SPECIFIED IN THIS SECTION WILL SURVIVE AND APPLY EVEN
              IF ANY LIMITED REMEDY SPECIFIED IN THESE TERMS IS FOUND TO HAVE
              FAILED OF ITS ESSENTIAL PURPOSE.
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
              INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS OR
              EXCLUSIONS MAY NOT APPLY TO YOU. THESE TERMS GIVE YOU SPECIFIC
              LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM
              JURISDICTION TO JURISDICTION. THE DISCLAIMERS, EXCLUSIONS, AND
              LIMITATIONS OF LIABILITY UNDER THESE TERMS WILL NOT APPLY TO THE
              EXTENT PROHIBITED BY APPLICABLE LAW.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c7">Indemnification</span>
          </p>
          <p class="c0 c5">
            <span class="c7"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Except to the extent prohibited under applicable law, you shall
              indemnify, defend and hold harmless RMC and its members, managers,
              directors, officers, employees, partners, consultants,
              contractors, service providers, agents, affiliates, successors and
              assigns from and against any and all suits, actions, proceedings
              and claims by third parties (whether threatened or actual), and
              all losses, liabilities, damages, judgments, costs and expenses
              (including reasonable legal fees) arising out of, relating to or
              in connection with: (i) your use (or misuse) of and access to the
              Service or Content; (ii) your violation of any of these Terms;
              (iii) your violation of any applicable law, rule or regulation;
              (d) any claim that any information provided by you to RMC in
              connection with the Platform, including the Content, caused damage
              to, infringed upon, misappropriated or otherwise violated the
              rights of any third party, including infringement,
              misappropriation or other violation of third-party intellectual
              property rights, or violation of any right of privacy or
              publicity; and/or (iv) any dispute that you have with any third
              party relating to or in connection with the Service or Content.
              RMC reserves the right, at its own cost, to assume the exclusive
              defense and control of any matter otherwise subject to
              indemnification by you, in which event you will fully cooperate
              with RMC in asserting any available defenses and in the conduct of
              such defense.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c7">Third Party Websites</span>
          </p>
          <p class="c0 c5">
            <span class="c7"></span>
          </p>
          <p class="c0">
            <span class="c1">
              The Service may contain links to third-party websites. Your use of
              all links to third-party websites is at your own risk. We do not
              monitor or have any control over, and make no claim or
              representation regarding third-party websites. To the extent such
              links are provided by us, they are provided only as a convenience,
              and a link to a third-party website does not imply our
              endorsement, adoption or sponsorship of, or affiliation with, such
              third-party websites.
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              When you leave the Platform, whether via a link contained on the
              Platform or through the use of your web browser, mobile device or
              other navigational tool, the information you view is not provided
              by us. Our terms and policies do not govern your use of
              third-party websites. We are not responsible for, have no control
              over and do not monitor or review the content of any other
              website. A link to a third-party website does not imply
              sponsorship, approval, affiliation or endorsement by RMC of the
              linked third-party website or of that third party's products or
              services.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              YOU AGREE THAT RMC WILL NOT, UNDER ANY CIRCUMSTANCES, BE
              RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY GOODS,
              SERVICES, INFORMATION, RESOURCES AND/OR CONTENT AVAILABLE ON OR
              THROUGH ANY THIRD-PARTY WEBSITE OR SERVICES, FOR ANY DEALINGS OR
              COMMUNICATIONS YOU MAY HAVE WITH THIRD PARTIES, OR FOR ANY HARM,
              DAMAGES OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION
              WITH ANY OF THE FOREGOING OR YOUR USE OF OR RELIANCE ON THE
              MATERIALS OR THE CONTENT OR BUSINESS PRACTICES OF ANY THIRD PARTY.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c7"></span>
          </p>
          <p class="c0">
            <span class="c7">Modification of the Website</span>
          </p>
          <p class="c0 c5">
            <span class="c7"></span>
          </p>
          <p class="c0">
            <span class="c1">
              At any time and in RMC's sole and absolute discretion, RMC may (in
              whole or in part) modify, suspend or discontinue the Service
              and/or any Content without prior notice, for any reason
              whatsoever. We shall have no liability to you or to any third
              party for any such modification, suspension or discontinuance.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c7">Changes to these Terms</span>
          </p>
          <p class="c0 c5">
            <span class="c7"></span>
          </p>
          <p class="c0">
            <span class="c1">
              We may revise these Terms from time to time, without prior notice.
              You are bound by any changes to these Terms upon our posting of
              such changes on the Service. You should check these Terms often to
              make certain that you are aware of the most current Terms.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c7">Enforcement</span>
          </p>
          <p class="c0 c5">
            <span class="c7"></span>
          </p>
          <p class="c0">
            <span class="c1">
              The remedies available to RMC in these Terms are cumulative and in
              addition to any others available to RMC. RMC may seek all remedies
              available to it at law and in equity for any violation of these
              Terms. RMC may suspend, terminate or block your access to the
              Service (in whole or in part) for any violation or suspected
              violation as we determine, without notice to you. Your violation
              of these Terms shall be considered a breach of contract.
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              We reserve the right, but do not assume any obligation, to
              investigate any suspected violation of these Terms or any misuse
              of the Service. In addition, we further reserve the right to
              report any activity, data or persons to, and otherwise cooperate
              with: (i) law enforcement authorities; (ii) financial regulators,
              including the U.S. Securities and Exchange Commission (SEC) and
              the Monetary Authority of Singapore; (iii) system administrators
              at Internet service providers, networks or computing facilities;
              and (iv) providers and/or third-party vendors if we suspect that
              you have violated these Terms or any law, rule or regulation. You
              acknowledge that such reporting or cooperation may include,
              without limitation, providing information relating to you and/or
              your use of the Service, including without limitation your email
              address, IP address or other identifying information, to law
              enforcement authorities, financial regulators, third-party
              providers, vendors or system administrators. Further, we may
              disclose any information we think necessary to comply with
              applicable law, regulation, subpoena or other legal process or
              governmental or regulatory request.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c7">Governing Law; Submission to Jurisdiction</span>
          </p>
          <p class="c0 c5">
            <span class="c7"></span>
          </p>
          <p class="c0">
            <span class="c1">
              These Terms and any and all claims, disputes or other legal
              proceedings by or between you and us, including but not limited to
              any claims or disputes that are in any way related to or arising
              out of these Terms or your use of or access to the Service, shall
              be governed by and construed in accordance with U.S. federal law
              and the laws of Delaware, without regard to any principles of
              conflicts of law. You agree that any action arising out of,
              relating to or in connection with the Service, and/or these Terms
              shall be litigated solely in a state or federal court of competent
              jurisdiction located in Delaware, and you further irrevocably
              submit to the exclusive jurisdiction of such court and waive any
              objection to jurisdiction and venue (including on the basis of
              forum non-conveniens).
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              You acknowledge that any breach of either of the sections above
              titled "Prohibited Activities" or "Ownership and Intellectual
              Property" would cause immediate and irreparable harm to RMC, for
              which monetary damages would not be sufficient, and that, in
              addition to all other remedies available to RMC at law or in
              equity, RMC shall be entitled to seek injunctive relief without
              proof of damages or the posting of bond or other security in the
              event of such a breach or threatened breach.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c7">General</span>
          </p>
          <p class="c0 c5">
            <span class="c7"></span>
          </p>
          <p class="c0">
            <span>
              These Terms (and any other terms or agreements referenced herein,
              including our Privacy Policy, which is located at{" "}
            </span>
            <span class="c6">
              <a
                class="c23"
                href="https://www.google.com/url?q=https://ramzmarketcap.com/privacy/&amp;sa=D&amp;source=editors&amp;ust=1695950451432406&amp;usg=AOvVaw2RZMP-ajCw2UJCmjn-Xcq5"
              >
                https://ramzmarketcap.com/privacy/
              </a>
            </span>
            <span class="c1">
              &nbsp;), constitute the entire agreement between you and RMC
              relating to your use of the Service and supersede all prior or
              contemporaneous communications, whether electronic, oral or
              written, between you and RMC with respect to the Service. A
              printed version of these Terms and of any notice given in
              electronic form shall be admissible in judicial or administrative
              proceedings based upon or relating to these Terms to the same
              extent and subject to the same conditions as other business
              documents and records originally generated and maintained in
              printed form.
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              You agree that no joint venture, agency, partnership, or
              employment relationship exists between you and RMC and/or its
              affiliates as a result of these Terms or use of the Service.
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              In no event shall RMC be responsible or liable for any failure or
              delay in the performance of its obligations hereunder arising out
              of or caused by, directly or indirectly, forces beyond its
              control, including, without limitation, strikes, work stoppages,
              accidents, acts of war or terrorism, civil or military
              disturbances, pandemics, nuclear or natural catastrophes or acts
              of God, and interruptions, loss or malfunctions of utilities,
              communications or computer (software and hardware) services.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Our performance under these Terms is subject to existing laws and
              legal process, and nothing contained in these Terms limits our
              right to comply with law enforcement or other governmental or
              legal requests or requirements relating to your use of the Service
              or information provided to, or gathered by, us with respect to
              such use. To the extent allowed by applicable law, you agree that
              you will bring any claim or cause of action arising from or
              relating to your access or use of this Service within two (2)
              years from the date on which such claim or action arose or accrued
              or such claim or cause of action will be irrevocably waived.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              These Terms, and any rights and licenses granted hereunder, may
              not be transferred or assigned by you, but may be assigned by RMC
              without restriction. Any attempted transfer or assignment by you
              in violation hereof shall be null and void.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              If any provision of these Terms, including, but not limited to,
              the warranty disclaimers and limitations of liability set forth
              above, is determined to be invalid or unenforceable under
              applicable law, the invalid or unenforceable provisions in these
              Terms shall be deemed superseded by valid and enforceable
              provisions that, to the extent possible, fulfill the business
              purposes and intent of such invalid and unenforceable provisions.
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              In addition to and without limiting the preceding paragraph, some
              jurisdictions may provide rights in addition to those provided in
              the above "Disclaimers and Limitation of Liability" section or do
              not allow the exclusion or limitation of implied warranties or
              liability for incidental or consequential damages. Therefore, the
              limitations set forth in the above Disclaimer and Limitation of
              Liability section may not apply to you in whole or in part or
              there may be state or country specific provisions that supersede
              such limitations in whole or in part. Any provision of the above
              Disclaimer and Limitation of Liability section that is declared
              invalid shall be deemed severable and shall not affect the
              validity or enforceability of the remainder.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Any heading or section title contained herein is for convenience
              of reference only and shall not affect the meaning or
              interpretation of these Terms. The terms "include" and "including"
              are deemed to include the phrase "without limitation" immediately
              thereafter.
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              Unless expressly provided to the contrary in these Terms, a person
              who is not a party to these Terms has no rights at law and in
              equity to enforce or enjoy the benefit of any term in these Terms.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c7">
              RamzMarketCap.com VIRTUAL EVENT TERMS &amp; CONDITIONS
            </span>
          </p>
          <p class="c0 c5">
            <span class="c7"></span>
          </p>
          <p class="c0">
            <span class="c18">Tijarah Holding Sdh. Bhd.</span>
            <span>, a Labuan offshore limited company</span>
            <span class="c1">
              &nbsp;administers and maintains the RamzMarketCap.com (RMC)
              website. RMC also hosts and provides access to online events, such
              as but not limited to conferences and webinars, from time to time
              (collectively, “Virtual Events”).
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span>
              These virtual event terms and conditions (collectively, the "VE
              Terms") govern your access to and participation in Virtual Events
              organized by RMC. These VE Terms apply in addition to the RMC
              Terms of Use (accessible here:{" "}
            </span>
            <span class="c6">
              <a
                class="c23"
                href="https://www.google.com/url?q=https://ramzmarketcap.com/terms/&amp;sa=D&amp;source=editors&amp;ust=1695950451433674&amp;usg=AOvVaw3pqZsuMpvzFn1kBkfPL2tC"
              >
                https://ramzmarketcap.com/terms/
              </a>
            </span>
            <span>&nbsp;) and RMC Privacy Policy (accessible here: </span>
            <span class="c6">
              <a
                class="c23"
                href="https://www.google.com/url?q=https://ramzmarketcap.com/privacy/&amp;sa=D&amp;source=editors&amp;ust=1695950451433770&amp;usg=AOvVaw3syzeJZ6vb7sLc-m49Hzfd"
              >
                https://ramzmarketcap.com/privacy/
              </a>
            </span>
            <span class="c1">
              &nbsp;), the terms of which are also incorporated by reference
              herein. In the event of any inconsistency, these VE Terms shall
              take precedence, but only to the extent of such inconsistency.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              By registering your own participation in RMC’s Virtual Events
              (“Registered Participant”), you irrevocably agree to accept these
              VE Terms. If you are registering for and on behalf of another
              individual, you are warranting that the other individual has
              already been made aware of and agrees to be bound by these VE
              Terms.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c7">REGISTRATION</span>
          </p>
          <p class="c0 c5">
            <span class="c7"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Registered Participants shall be entitled to access the Virtual
              Event for which registration was made. When registering for the
              Virtual Event, RMC may request you to provide certain information.
              In such an event, you agree to provide RMC with accurate
              information. Upon successful registration, you also agree: (i) not
              to sell, transfer or share your Virtual Event passes to anyone
              else, without the prior approval of RMC; (ii) not to share with
              any other person, your user ID or passwords that RMC may issue to
              you as part of your registration; and that (iii) you are
              responsible for keeping your user ID and password securely, and
              not to knowingly permit any breach of security (such as through
              unauthorized use of your user ID and password by others).
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              All costs associated with your attendance for the Virtual Event
              (including costs of registration, if any) shall be borne solely by
              you. Unless stated otherwise, all registration fees that are
              payable in respect of registration for a Virtual Event (if any),
              shall be paid upfront and in full to RMC.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Once your registration has been confirmed by RMC, such
              registration shall be non-cancellable, and any registration fees
              paid (if any) shall be non-refundable. For clarity, there shall
              also be no refund of registration fees already paid (if any), even
              if the Registered Participant does not eventually attend the
              Virtual Event.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Although details of the Virtual Event (such as but not limited to
              agenda line-up, event date and time) are correct at time of
              marketing, publicity and publication, Registered Participant
              nonetheless acknowledges and accepts that there may be
              circumstances where the Virtual Event may have to be canceled or
              postponed (or the agenda or speaker line-up changed), through no
              fault of RMC. Such events may include (but is not limited) to
              situations involving a last minute change to speaker availability.
              RMC shall not be liable to Registered Participants in such an
              event, and Registered Participants are also not entitled to any
              refund of registration fees already paid (if any).
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Without prejudice to clause 7 above, if the Virtual Event is
              eventually canceled in its entirety (without any postponement),
              RMC may (but shall not be obliged) in its sole and absolute
              discretion, decide to refund registration fees that might have
              been paid (if any).
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c7">VIRTUAL EVENT AGENDA</span>
          </p>
          <p class="c0 c5">
            <span class="c7"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Registered Participants agree that RMC may, in its sole and
              absolute discretion, update and change the Virtual Event agenda
              and format at any time without prior notice. For clarity, the
              rights under this clause shall also include, but not limited to, a
              right to change the speaker line-up, venue, time, date, and topics
              to be covered at the event.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c7">CONDUCT OF REGISTERED PARTICIPANTS</span>
          </p>
          <p class="c0 c5">
            <span class="c7"></span>
          </p>
          <p class="c0">
            <span class="c1">Registered Participants agree and warrant:</span>
          </p>
          <ul class="c17 lst-kix_bhh4dtwcxbw8-0 start">
            <li class="c0 c4 li-bullet-0">
              <span class="c1">
                not to conduct themselves in such manner as to disrupt conduct
                of the Virtual Event, or restrict, inhibit, hinder or interfere
                with other Register’s Participant’s enjoyment of the Virtual
                Event;
              </span>
            </li>
            <li class="c0 c4 li-bullet-0">
              <span class="c1">
                not to engage in any unethical business practices as RMC may
                determine from time to time;
              </span>
            </li>
            <li class="c0 c4 li-bullet-0">
              <span class="c1">
                not to use or introduce any data mining, crawling, "scraping",
                robot or similar automated or data gathering or extraction
                method, or manually access, acquire, monitor or copy any portion
                of the Virtual Event, or download or store content relating to
                the Virtual Event, unless permitted by RMC;
              </span>
            </li>
            <li class="c0 c4 li-bullet-0">
              <span class="c1">
                not to copy, reproduce, download, "screen scrape", store,
                transmit, broadcast, publish, modify, create a derivative work
                from, display, perform, distribute, redistribute, sell, license,
                rent, lease or otherwise use, transfer (either in printed,
                electronic or other format) or exploit any content in respect of
                the Virtual Event and its contents, unless permitted by RMC;
              </span>
            </li>
            <li class="c0 c4 li-bullet-0">
              <span class="c1">
                not to infringe the intellectual property rights of RMC in
                respect of the Virtual Event and its contents;
              </span>
            </li>
            <li class="c0 c4 li-bullet-0">
              <span class="c1">
                to always behave in a respectful, professional and appropriate
                manner during the Virtual Event, and to other Registered
                Participants and attendees (including but not limited to the
                speakers, hosts, moderators, etc);
              </span>
            </li>
            <li class="c0 c4 li-bullet-0">
              <span class="c1">
                to comply strictly with these VE Terms, and any other policies,
                rules and procedures that RMC may set from time to time, in
                relation to Registered Participants’ access to, participation in
                and conduct during the Virtual Event;
              </span>
            </li>
          </ul>
          <p class="c0">
            <span class="c1">
              they are solely responsible for ensuring that they procure and
              possess the right equipment and IT set-up necessary to access the
              Virtual Event, and that RMC shall not be liable or responsible to
              Registered Participants for the reliability or continued
              availability or speed or quality of the internet connectivity or
              equipment that Registered Participants may use to access and
              participate in the Virtual Events, or any interruptions due to
              technical compatibility issues (if there are any questions
              relating to the technical requirements needed to access and
              participate in the Virtual Events, Registered Participants can
              contact RMC directly for assistance); and
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              that they are not subject to any sanctions or trade controls or
              other restrictions that prohibit them from attending the Virtual
              Event; and that they are fully and legally entitled to sign up for
              and attend the Virtual Event.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              RMC reserves the right to deny participation to and/or remove a
              Registered Participant from Virtual Events that Registered
              Participant has registered for (including at any time during the
              Virtual Event itself), if such Registered Participant fails to
              comply with any obligation contained in these VE Terms. In
              addition, RMC may also deny participation to prospective
              participants at its sole and absolute discretion.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c7">RIGHT TO RECORD, PHOTOGRAPH, ETC</span>
          </p>
          <p class="c0 c5">
            <span class="c7"></span>
          </p>
          <p class="c0">
            <span class="c1">
              By participating in the Virtual Event, you agree to grant RMC a
              right to record, film, live stream, and take photographs of the
              Virtual Event and Registered Participants (including you), as well
              as to distribute, broadcast, use or generally disseminate
              recordings and photographs of the same in any media, without
              further approval from or payment to you. This right shall include,
              but is not limited to, a right by RMC to use recordings and
              photographs of you, or your likeness, in future materking
              materials, including social medical channels, websites and print
              material.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Registered Participants shall not be allowed to record, film, live
              stream, and take photographs of the Virtual Event and other
              Registered Participants.
            </span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Terms_of_service;
