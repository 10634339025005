import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";

function Profile({ url, Dissconnect, Metamask, WalletC }) {
  const user_detail = JSON.parse(localStorage.getItem("user_detail"));

  return (
    <div>
      {" "}
      <Navbar Dissconnect={Dissconnect} Metamask={Metamask} WalletC={WalletC} />
      <div className="container-kws">
        <div className="section">
          <div className="max-w-30 b-r-20 pa-2 t-a-c   d-b m-a bg_g_clr">
            <div className="m-y-0_5">
              <img
                src={user_detail.profileImage}
                className="w-12 b-r-50 d-b m-a"
              />
            </div>
            <div className="f-s-1_5 f-w-600 m-b-1">{user_detail.name}</div>
            <div className="f-s-1_5 f-w-600 m-b-1">{user_detail.email}</div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Profile;
