import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";

function Pricvacy({ url, Dissconnect, Metamask, WalletC }) {
  return (
    <>
      <Navbar Dissconnect={Dissconnect} Metamask={Metamask} WalletC={WalletC} />
      <div className="">
        <div className="container-kws">
          {" "}
          <p class="c13 c22 m-b-2 f-s-2 f-w-600">
            <span class="c18 c27">Privacy Policy</span>
          </p>
          <p class="c10 c13">
            <span class="c19">Effective Date: September 27, 2023.</span>
          </p>
          <p class="c10">
            <span class="c2">
              At RamzMarketCap, we’re strongly committed to protecting and
              respecting your privacy.
            </span>
          </p>
          <p class="c10">
            <span class="c2">
              This Privacy Policy (“Privacy Policy”) covers your access and use
              of this website, ramzmarketcap.com (the “Website”), as well as
              associated content, software, and mobile applications
              (collectively, the “Service”).
            </span>
          </p>
          <p class="c10">
            <span class="c2">
              This Privacy Policy explains when, why and how we collect and use
              personal information about people who visit our Website or use our
              Services, the conditions under which we may disclose your personal
              information to others, and how we keep your personal information
              secure.
            </span>
          </p>
          <p class="c10">
            <span class="c2">
              We may change this Privacy Policy from time to time. By using our
              Website or our Services, you are agreeing to be bound by this
              Privacy Policy.
            </span>
          </p>
          <h4 class=" c11 m-t-2 m-b-1" id="h.ajtbs4xw8fl8">
            <span class="c9">What Information Do We Collect?</span>
          </h4>
          <p class="c10">
            <span class="c12">RamzMarketCap</span>
            <span class="c2">
              &nbsp;(“RamzMarketCap”, “we” or “us”) collects (a) the e-mail
              addresses of those who communicate with us via email; (b)
              aggregate information concerning what pages users access or visit;
              (c) information given by the user (such as survey information
              and/or site registrations); and (d) information related to your
              use of the Website and/or the mobile application, including IP
              address, Device ID, geographic location, and date and time of your
              request; (e) any other information that you might provide
              voluntarily. We might also collect your email address and your
              Wallet ID if you decide to participate in one of our campaigns.
            </span>
          </p>
          <p class="c10">
            <span class="c2">
              We do not knowingly collect information from children under the
              age of 18. By using the RamzMarketCap service, you are
              representing that you are at least 18 years old.
            </span>
          </p>
          <h4 class=" c11 m-t-2 m-b-1" id="h.lov4snc0zaez">
            <span class="c9">How Do We Use the Information?</span>
          </h4>
          <p class="c10">
            <span class="c12">RamzMarketCap</span>
            <span class="c2">
              &nbsp;uses collected information for the following purposes:
            </span>
          </p>
          <ol class="c17 lst-kix_39nyp7oxa51r-0 start" start="1">
            <li class="c14 li-bullet-0">
              <span class="c2">
                To fulfill a contract or take steps linked to a contract such as
                processing your registration on our Website or sending you
                information about changes to our terms or policies;
              </span>
            </li>
            <li class="c14 li-bullet-0">
              <span class="c2">
                Where it is necessary for purposes which are in RamzMarketCap’s
                legitimate interests such as (a) to provide the information or
                content you have requested; (b) to contact you about our
                programs, products, features or services; (c) to improve our
                services and for internal business purposes such as
                identification and authentication or customer service, portfolio
                tracking and user preference syncing between devices; (d) to
                ensure the security of our Website, by trying to prevent
                unauthorized or malicious activities; (e) to enforce compliance
                with our Terms of Use and other policies; (f) to help other
                organizations (such as copyright owners) to enforce their
                rights; and (g) to tailor content, advertisements, and offers
                for you or for other purposes disclosed at the time of
                collection.
              </span>
            </li>
            <li class="c14 li-bullet-0">
              <span class="c2">
                If you do not wish to receive marketing information about our
                programs, products, features or services, you can opt-out of
                marketing communications.
              </span>
            </li>
            <li class="c14 li-bullet-0">
              <span class="c2">
                Where you give us consent, such as (a) where you ask us to send
                marketing information to you via a medium where we need your
                consent, including alerts via mobile push notifications; (b)
                where you give us consent to place cookies and to use similar
                technologies; and (c) on other occasions where we ask you for
                consent, for any other purpose that might arise.
              </span>
            </li>
            <li class="c14 li-bullet-0">
              <span class="c2">
                Where we are legally required to do so. We may also provide
                access to your personally identifiable information when legally
                required to do so, to cooperate with police investigations or
                other legal proceedings, to protect against misuse or
                unauthorized use of our Website, to limit our legal liability
                and protect our rights, or to protect the rights, property or
                safety of visitors of the Website or the public. In those
                instances, the information is provided only for that purpose.
              </span>
            </li>
          </ol>
          <h4 class="c11 c26" id="h.cdoq1p79iecf">
            <span class="c9">How Do We Share Your Information?</span>
          </h4>
          <p class="c10 c26">
            <span class="c2">
              We do not sell your personal data to other organizations for
              commercial purposes. We only share your personal information to
              provide products or services you’ve requested, when we have your
              permission, or under the following circumstances:
            </span>
          </p>
          <ul class="c17 lst-kix_6zqsw0hleok8-0 start">
            <li class="c8 li-bullet-0">
              <span class="c2">
                When it is necessary to share information in order to
                investigate, prevent, or take action regarding illegal
                activities, suspected fraud, situations involving potential
                threats to the physical safety of any person, violations of
                Terms of Service, available at https://ramzmarketcap.com/terms/
                or as otherwise required by law.
              </span>
            </li>
            <li class="c8 li-bullet-0">
              <span class="c2">
                We provide personal information to trusted businesses or persons
                for the sole purpose of processing personally identifying
                information on our behalf and provide us with services. When
                this is done, it is subject to agreements that oblige those
                parties to process such information only on our instructions and
                in compliance with this Privacy Policy and appropriate
                confidentiality and security measures.
              </span>
            </li>
            <li class="c8 li-bullet-0">
              <span class="c2">
                We provide such information to third parties who have entered
                into non-disclosure agreements with us.
              </span>
            </li>
            <li class="c8 li-bullet-0">
              <span class="c2">
                We provide such information to a company controlled by, or under
                common control with, RamzMarketCap for any purpose permitted by
                this Privacy Policy.
              </span>
            </li>
            <li class="c8 li-bullet-0">
              <span class="c2">
                We may aggregate, anonymize, and publish data for statistical
                and research purposes only. For example, we may compile and
                share information related to the popularity of certain products
                tracked by users. In any such instance, the information will not
                be able to be traced back to any individual.
              </span>
            </li>
          </ul>
          <h4 class="c24 m-t-2 m-b-1" id="h.108nnf80ilxd">
            <span class="c9">Events</span>
          </h4>
          <p class="c15">
            <span class="c2">
              In connection with events, RamzMarketCap collects your a) prefix,
              b) name, c) email address, d) company name, e) role, f) job level,
              g) industry, h) state and country, i) LinkedIn profile, j) card
              payment details and k) billing address. We collect this data from
              you to facilitate the ticketing process, to engage in internal
              analytics, for internal RMC marketing purposes and for external
              marketing by a third party, the event sponsors. The following data
              is shared with event sponsors: your a) name, b) title, c) company
              and d) email address. The following data is shared with the event
              platform: a) all details that are inserted into the registration
              form by you, the event attendee, and b) your payment details.
            </span>
          </p>
          <h4 class="c24 m-t-2 m-b-1" id="h.e5gdfy778383">
            <span class="c9">Security</span>
          </h4>
          <p class="c15">
            <span class="c2">
              We take precautions to ensure the security of your personal
              information. We ensure that our Website is protected by reasonable
              security measures afforded by current technology, and that all our
              data hosts and servers are similarly protected by such security
              measures, including but not limited to firewalls. We strongly
              advise you to change your password frequently and not to use the
              same password for different websites. We cannot protect, nor does
              this Privacy Policy apply to, any information that you transmit to
              other users. You should never transmit personal or identifying
              information to other users.
            </span>
          </p>
          <h4 class=" c11 m-t-2 m-b-1" id="h.hoxrp8lc1l9o">
            <span class="c9">Retention of Your Personal Information</span>
          </h4>
          <p class="c10">
            <span class="c2">
              We retain information as long as it is necessary to provide the
              Services requested by you and others, subject to any legal
              obligations to further retain such information. Information
              associated with your account will generally be kept until it is no
              longer necessary to provide the Services or until you ask us to
              delete it or your account is deleted, whichever comes first.
              Additionally, we may retain information from deleted accounts to
              comply with the law, prevent fraud, resolve disputes, troubleshoot
              problems, assist with investigations, enforce the Terms of Use,
              and take other actions permitted by law. The information we retain
              will be handled in accordance with this Privacy Policy.
            </span>
          </p>
          <h4 class=" c11 m-t-2 m-b-1" id="h.pedlvrggkajr">
            <span class="c9">EU and EEA Users’ Rights</span>
          </h4>
          <p class="c10">
            <span class="c2">
              If you are a resident of the European Economic Area, you generally
              have the right to access, rectify, download or erase your
              information, as well as the right to restrict and object to
              certain processing of your information. While some of these rights
              apply generally, certain rights apply only in certain limited
              circumstances.
            </span>
          </p>
          <p class="c10">
            <span class="c2">
              You have the right to access your personal data and, if necessary,
              have it amended, deleted or restricted. In certain instances, you
              may have the right to the portability of your data. Please contact
              legal@ramzmarketcap.com to fulfill any requests relating to your
              data subject rights.
            </span>
          </p>
          <p class="c10">
            <span class="c2">
              You can also ask us not to send marketing communications and not
              to use your personal data when we carry out profiling for direct
              marketing purposes. You can opt out of receiving e-mail
              newsletters and other marketing communications by following the
              opt-out instructions provided to you in those e-mails.
              Transactional account messages will be unaffected even if you opt
              out from marketing communications.
            </span>
          </p>
          <h4 class=" c11 m-t-2 m-b-1" id="h.jx8pp4hqs6nw">
            <span class="c9">Complaints</span>
          </h4>
          <p class="c10">
            <span class="c2">
              Should you wish to raise a concern about our use of your
              information (and without prejudice to any other rights you may
              have), please reach out to legal@ramzmarketcap.com. If our reply
              is not satisfactory, you have the right to raise a concern with
              your local supervisory authority, depending on where you are
              located.
            </span>
          </p>
          <h4 class=" c11 m-t-2 m-b-1" id="h.p1f4sypl5ob3">
            <span class="c9">Your California Privacy Rights</span>
          </h4>
          <p class="c10">
            <span class="c12">RamzMarketCap</span>
            <span class="c2">
              &nbsp;may have collected, used, and shared, for business purposes,
              personal information about you as described in this Privacy
              Policy. Each category of data may be used by RamzMarketCap or
              shared with third parties also as described in this Privacy
              Policy. Residents of California have the right to request access
              to and deletion of the information RamzMarketCap holds about them.
              Such requests may be submitted by email to
              legal@ramzmarketcap.com. RamzMarketCap will not sell your personal
              information without your consent. RamzMarketCap will not
              discriminate against you for exercising your rights under CCPA.
              Specifically, we will not: Deny access to our Services; Charge a
              different rate for the use of our Services; or, Provide a
              different quality of service.
            </span>
          </p>
          <h4 class=" c11 m-t-2 m-b-1" id="h.o9kid0icfelm">
            <span class="c9">Questions</span>
          </h4>
          <p class="c10">
            <span class="c12">
              Any questions about this Privacy Policy or about our use of your
              personal information should be addressed to{" "}
            </span>
            <span class="c6 c13 c21">
              <a class="c23" href="mailto:legal@ramzmarketcap.com">
                legal@ramzmarketcap.com
              </a>
            </span>
            <span class="c2">.</span>
          </p>
          <p class="c10 c5">
            <span class="c2"></span>
          </p>
          <p class="c10 c5">
            <span class="c2"></span>
          </p>
          <p class="c10">
            <span class="c13 c16">Disclaimer</span>
          </p>
          <p class="c10">
            <span class="c13 c18 c20">No Investment Advice</span>
          </p>
          <p class="c10">
            <span class="c2">
              The information provided on this website does not constitute
              investment advice, financial advice, trading advice, or any other
              sort of advice and you should not treat any of the website's
              content as such. CoinMarketCap does not recommend that any
              cryptocurrency should be bought, sold, or held by you. Do conduct
              your own due diligence and consult your financial advisor before
              making any investment decisions.
            </span>
          </p>
          <p class="c10">
            <span class="c20 c13 c18">Accuracy of Information</span>
          </p>
          <p class="c10">
            <span class="c2">
              CoinMarketCap will strive to ensure accuracy of information listed
              on this website although it will not hold any responsibility for
              any missing or wrong information. CoinMarketCap provides all
              information as is. You understand that you are using any and all
              information available here at your own risk.
            </span>
          </p>
          <p class="c10">
            <span class="c20 c13 c18">Non Endorsement</span>
          </p>
          <p class="c10">
            <span class="c2">
              The appearance of third party advertisements and hyperlinks on
              CoinMarketCap does not constitute an endorsement, guarantee,
              warranty, or recommendation by CoinMarketCap. Do conduct your own
              due diligence before deciding to use any third party services.
            </span>
          </p>
          <p class="c10">
            <span class="c20 c13 c18">Affiliate Disclosure</span>
          </p>
          <p class="c10">
            <span class="c2">
              CoinMarketCap may receive compensation for affiliate links. This
              compensation may be in the form of money or services and could
              exist without any action from a site visitor. Should you perform
              activities in relation to an affiliate link, it is understood that
              some form of compensation might be made to CoinMarketCap. For
              example, if you click on an affiliate link, and sign up and trade
              on an exchange, CoinMarketCap may receive compensation. Each
              affiliate link is clearly marked with an icon next to it.
            </span>
          </p>
          <p class="c10 c5">
            <span class="c2"></span>
          </p>
          <p class="c10 c5">
            <span class="c2"></span>
          </p>
          <p class="c0 c5">
            <span class="c1"></span>
          </p>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Pricvacy;
