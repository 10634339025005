import React, { useEffect, useState } from "react";
import axios from "axios";
import Super_admin_navbar from "./Super_admin_navbar";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

const notify = (msg) => toast.success(msg);
const notifyError = (msg) => toast.error(msg);
const notifyMessage = (msg) =>
  toast(msg, {
    duration: 10000,
  });

function Super_admin({ url }) {
  const [email, setemails] = useState("");
  const [password, setpasswords] = useState("");
  const [token, setToken] = useState("");
  const [protectedData, setProtectedData] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${url}/login786`, {
        email: email,
        password: password,
      });
      const data = response.data;
      localStorage.setItem("signup", JSON.stringify(response.data));
      notify("Successful Login");
      window.location.href = `/admin`;
      console.log("Token:", data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="p-t-7">
        <div className="container-kws">
          <div className="m-y-3 f-s-2 t-a-c f-w-600">Super Admin Panel</div>
          <div className="row m-y-1">
            <div className="col-4 f-s-1_25 f-w-600">Email</div>
            <div className="col-8">
              <input
                type="email"
                className="w-100 b-1 b-r-5 pa-0_5"
                placeholder="enter email ...."
                value={email}
                onChange={(e) => setemails(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4 f-s-1_25 f-w-600">Password</div>
            <div className="col-8">
              <input
                type="password"
                className="w-100 b-1 b-r-5 pa-0_5"
                placeholder="enter password ...."
                value={password}
                onChange={(e) => setpasswords(e.target.value)}
              />
            </div>
          </div>
          <div className=" t-a-c m-t-3">
            <button
              className="f-w-600 f-s-1_5 b-c-t c-w b-1 b-r-20 p-x-3 p-y-0_5"
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
      <div className="m-t-2 t-a-c">
        <Link to="/forgot_password">
          Forgot Password or Change Password Click here
        </Link>
      </div>
      <div className="m-t-0_5 t-a-c">
        <Link to="/ChangeEmail">Forgot Email or Change Email Click here</Link>
      </div>
      <Toaster position="top-center" reverseOrder={true} />
    </div>
  );
}

export default Super_admin;
