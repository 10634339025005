import React, { useEffect, useLayoutEffect, useState } from "react";
import { ImCross } from "react-icons/im";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Dropdown from "react-bootstrap/Dropdown";
import {
  AiFillStar,
  AiOutlineHome,
  AiOutlineLogout,
  AiOutlineMenu,
  AiOutlineProfile,
  AiOutlineSearch,
} from "react-icons/ai";
import { BiGasPump } from "react-icons/bi";
import ice_pie from "../Image/ice_pie.png";
import { HiOutlineDocumentRemove } from "react-icons/hi";
import Modal from "react-bootstrap/Modal";
import meta from "../Image/meta.png";
import walletconnect from "../Svg/walletconnect.svg";
import "animate.css";
import { CgProfile } from "react-icons/cg";

import logo from "../Image/nfe-logo.png";
import { Link } from "react-router-dom";

function Navbar() {
  const clientId =
    "BO7wL8pIrRBzanGu0m6UdgrNfmR29fRR2k77UYx8aWcNEKB750epjeMveEBnuHPNYJWDOZdmoFkiQNvQUk93QEA";
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [web3auth, setWeb3auth] = useState(null);
  const [provider, setProvider] = useState(null);
  const [Address, setAddress] = useState("");
  const [balance, setBalance] = useState("");
  const [chainId, setChainId] = useState("");
  const [userData, setUserData] = useState({});

  let styles = {
    button: {
      width: "100%",
      maxWidth: 200,
      cursor: "pointer",
      background: "#8347E5",
      border: "1px solid #8347E5",
      boxSizing: "border-box",
      borderRadius: "15px",
      fontSize: 16,
      color: "#000000",
      fontWeight: 700,
      padding: "12px 30px 12px 30px",
      marginTop: 15,
      display: "flex",
      justifyContent: "center",
    },
  };
  // useEffect(() => {
  //   const init = async () => {
  //     try {
  //       const web3auth = new Web3Auth({
  //         clientId,
  //         chainConfig: {
  //           chainNamespace: CHAIN_NAMESPACES.EIP155,
  //           chainId: "0x13881",
  //           rpcTarget: "https://rpc-mumbai.maticvigil.com/",
  //         },
  //       });

  //       setWeb3auth(web3auth);
  //       await web3auth.initModal();
  //       setProvider(web3auth.provider);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   init();
  // }, []);

  const logout_superadmin = () => {
    localStorage.removeItem("signup");

    window.location.href = `/super_admin`;
  };
  const logout_admin = () => {
    localStorage.removeItem("admin_login");
    window.location.href = `/admin_login`;
  };

  const signup = localStorage.getItem("signup");
  const admin_login = localStorage.getItem("admin_login");

  return (
    <>
      <div className="container-kws">
        <div className="d-f j-c-s-b a-i-c p-t-1">
          <div className="d-f a-i-c">
            <Link to="/">
              <div className="m-r-2">RamzMarketCap</div>
            </Link>
            <Link to="/admin">
              <div className="m-r-2">Admin</div>
            </Link>
            {signup ? (
              <Link to="/create_login">
                <div className="m-r-2">Create Admin</div>
              </Link>
            ) : (
              ""
            )}{" "}
            {signup ? (
              <Link to="/list_of_admin">
                <div className="m-r-2">List Of Admin</div>
              </Link>
            ) : (
              ""
            )}
          </div>
          <div className="d-f a-i-c">
            <div className="">
              {admin_login ? (
                <button
                  className="login b_clr_blue b_clr f-w-600  b-1 b-c-t pa-0_5 c-w b-r-10 p-x-1_5 p-y-0_5 m-l-2"
                  onClick={() => logout_admin()}
                >
                  Admin Logout
                </button>
              ) : (
                ""
              )}
              {signup ? (
                <button
                  className="login b_clr_blue b_clr f-w-600  b-1 b-c-t pa-0_5 c-w b-r-10 p-x-1_5 p-y-0_5 m-l-2"
                  onClick={() => logout_superadmin()}
                >
                  Super Admin Logout
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <hr />

      <Offcanvas show={show} onHide={handleClose} className="pa-1">
        <Offcanvas.Header>
          <Offcanvas.Title>
            <div className="image-dog1">
              <a href="/" target="_blank">
                <img src={logo} alt="" className="" />
              </a>
            </div>
          </Offcanvas.Title>
          <ImCross onClick={handleClose} color="black" />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="l-s-t-n">
            <li className="m-r-1 m-t-1 c-p">dummy</li>
            <li className="m-r-1 m-t-1 c-p">dummy</li>
            <li className="m-r-1 m-t-1 c-p">dummy</li>
            <li className="m-r-1 m-t-1 c-p">
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="b-c-t c-i b-n"
                >
                  Dropdown Button
                </Dropdown.Toggle>
                <Dropdown.Menu className="pa-1">
                  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    Another action
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    Something else
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal show={show2} onHide={handleClose2} className="">
        <div className="">
          <Modal.Header closeButton>
            <Modal.Title className="t-a-c m-a c-i">
              Please Connect Your Wallet
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="walletconnect d-f a-i-c j-c-s-e m-y-2">
              <img src={meta} alt="" className="w-7" />
              <img src={walletconnect} alt="" className="w-5" />
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default Navbar;
