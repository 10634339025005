import React, { useEffect, useState, useLayoutEffect } from "react";
import { MdArrowDropUp, MdKeyboardArrowRight } from "react-icons/md";

import {
  AiFillStar,
  AiOutlineDownload,
  AiOutlineShareAlt,
  AiOutlineStar,
} from "react-icons/ai";
import { Dropdown, Modal } from "react-bootstrap";
import explorers from "../Image/explorer.png";
import coin from "../Image/coin.png";

import { BsInfoCircle } from "react-icons/bs";
import shares from "../Image/shares.png";
import { RxCross2 } from "react-icons/rx";

import gcs from "../Image/gcs.png";
import exchange from "../Image/exchange.png";
import communitys from "../Image/community.png";
import github from "../Image/github.png";
import websites from "../Image/website.png";
import whitepapers from "../Image/whitepaper.png";
import dollar from "../Image/dollar.png";
import Footer from "./Footer";
import stars from "../Image/stars.png";
import Navbar from "./Navbar";
import Price_Chart from "./Price_Chart";
import { Tooltip } from "antd";
import { Link, useParams } from "react-router-dom";
import Price from "./Price";
import H24_changes from "./H24_changes";
import tick_blue from "../Image/tick_blue.png";
import tick_gold from "../Image/tick_gold.png";
import axios from "axios";
import Market_price from "./Market_price";
import toast, { Toaster } from "react-hot-toast";
import Volume from "./Volume";
import Disqus from "./Disqus";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Backdrop, CircularProgress } from "@mui/material";

const notify = (msg) => toast.success(msg);
const notifyError = (msg) => toast.error(msg);
const notifyMessage = (msg) =>
  toast(msg, {
    duration: 10000,
  });

function Crypto_detail({ url, Dissconnect, Metamask, WalletC }) {
  const [h_24, seth_24s] = useState(8);

  const [remark, setremarks] = useState();

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const { project_api_named } = useParams();
  console.log("wf", project_api_named);

  // console.log("a", id);

  const [value, setvalues] = useState();
  const [email, setemails] = useState();
  const [subject, setsubjects] = useState();
  const [Review, setReviews] = useState();
  const [Terms, setTermss] = useState();
  const [Declaration, setDeclarations] = useState();
  const [Disclaimer, setDisclaimers] = useState();
  const [relationship_project, setrelationship_projects] = useState();
  const [project_launch_date, setproject_launch_dates] = useState();
  const [project_name, setproject_names] = useState();
  const [project_ticker_symbol, setproject_ticker_symbols] = useState();
  const [cryptoasset, setcryptoassets] = useState();
  const [one_liner_description, setone_liner_descriptions] = useState();
  const [detailed_project_description, setdetailed_project_descriptions] =
    useState();
  const [platform, setplatforms] = useState();
  const [hashing_consensus_algorithm, sethashing_consensus_algorithms] =
    useState();
  const [team_backers_investors, setteam_backers_investorss] = useState();
  const [media_coverage, setmedia_coverages] = useState();
  const [traction_adoption, settraction_adoptions] = useState();
  const [country_origin, setcountry_origins] = useState();
  const [link_logo, setlink_logos] = useState();
  const [website, setwebsites] = useState();
  const [Annex_A, setAnnex_As] = useState();
  const [Emission, setEmissions] = useState();
  const [platform_contract_address, setplatform_contract_addresss] = useState();
  const [contract_address, setcontract_addresss] = useState();
  const [no_of_decimal, setno_of_decimals] = useState();
  const [block_explorer, setblock_explorers] = useState();
  const [source_code, setsource_codes] = useState();
  const [whitepaper, setwhitepapers] = useState();
  const [announcement, setannouncements] = useState();
  const [message_board, setmessage_boards] = useState();
  const [project_api_name, setproject_api_names] = useState();
  const [twitter, settwitters] = useState();
  const [reddit, setreddits] = useState();
  const [facebook, setfacebooks] = useState();
  const [project_introduction, setproject_introductions] = useState();
  const [video_channel, setvideo_channels] = useState();
  const [chat, setchats] = useState();
  const [linkedin, setlinkedins] = useState();
  const [mobile_app, setmobile_apps] = useState();
  const [circulating_supply, setcirculating_supplys] = useState();
  const [total_supply, settotal_supplys] = useState();
  const [max_supply, setmax_supplys] = useState();
  const [ico_start_date, setico_start_dates] = useState();
  const [ico_end_date, setico_end_dates] = useState();
  const [ico_launch_price, setico_launch_prices] = useState();
  const [api_live_price, setapi_live_prices] = useState();
  const [graph_historical_api, setgraph_historical_apis] = useState();
  const [cmc_supported_exchange, setcmc_supported_exchanges] = useState();
  const [attachment, setAttachment] = useState(null);
  const [address, setAddress] = useState();
  const [community, setcommunitys] = useState();
  const [open, setOpen] = useState(false);
  const [tick, setticks] = useState();

  const [gcs_prices, setgcs_pricess] = useState(null);
  const [xaus_prices, setxaus_pricess] = useState(null);

  useEffect(() => {
    const fetchDatassY = async () => {
      try {
        const response = await axios.get(
          "https://api-dex.gcex.lt/xaustousd/live",
        );

        setxaus_pricess(response.data.XAUS.usd);
        // setprices(response.data.XAUS.usd);
      } catch (error) {
        console.error("Error fetching crypto data:", error);
      }
    };

    fetchDatassY();
  }, []);

  useEffect(() => {
    const fetchDatass = async () => {
      try {
        const response = await axios.get(
          "https://api-dex.gcex.lt/gcstousd/live",
        );
        // const responseData = response.data.data;
        console.log("ef8454", response.data.GCS.usd);

        setgcs_pricess(response.data.GCS.usd);
        // setprices(response.data.GCS.usd);
      } catch (error) {
        console.error("Error fetching crypto data:", error);
      }
    };

    fetchDatass();
  }, []);

  // const [coinPrice, setcoinPrices] = useState("1");
  const [usd, setusds] = useState();

  useEffect(() => {
    PackagesDetails();
  }, [api_live_price]);

  const [crypt, setcryptos] = useState([]);

  const PackagesDetails = () => {
    try {
      axios.get(`${url}/api/crypto/${project_api_named}`).then((res) => {
        // console.log("gettingpacakgecarddetails", res.data);
        setemails(res.data.email);
        setAddress(res.data.address);
        setcommunitys(res.data.community);
        setsubjects(res.data.subject);
        setReviews(res.data.Review);
        setTermss(res.data.Terms);
        setticks(res.data.tick);
        setDeclarations(res.data.Declaration);
        setDisclaimers(res.data.Disclaimer);
        setrelationship_projects(res.data.relationship_project);
        setproject_launch_dates(res.data.project_launch_date);
        setproject_names(res.data.project_name);
        setproject_api_names(res.data.project_api_name);
        setproject_ticker_symbols(res.data.project_ticker_symbol);
        setcryptoassets(res.data.cryptoasset);
        setone_liner_descriptions(res.data.one_liner_description);
        setdetailed_project_descriptions(res.data.detailed_project_description);
        setplatforms(res.data.platform);
        sethashing_consensus_algorithms(res.data.hashing_consensus_algorithm);
        setteam_backers_investorss(res.data.team_backers_investors);
        setmedia_coverages(res.data.media_coverage);
        settraction_adoptions(res.data.traction_adoption);
        setcountry_origins(res.data.country_origin);
        setlink_logos(res.data.link_logo);
        setwebsites(res.data.website);
        setAnnex_As(res.data.Annex_A);
        setEmissions(res.data.Emission);
        setplatform_contract_addresss(res.data.platform_contract_address);
        setcontract_addresss(res.data.contract_address);
        setno_of_decimals(res.data.no_of_decimal);
        setblock_explorers(res.data.block_explorer);
        setsource_codes(res.data.source_code);
        setwhitepapers(res.data.whitepaper);
        setannouncements(res.data.announcement);
        setmessage_boards(res.data.message_board);
        settwitters(res.data.twitter);
        setreddits(res.data.reddit);
        setfacebooks(res.data.facebook);
        setproject_introductions(res.data.project_introduction);
        setvideo_channels(res.data.video_channel);
        setchats(res.data.chat);
        setlinkedins(res.data.linkedin);
        setmobile_apps(res.data.mobile_app);
        setcirculating_supplys(res.data.circulating_supply);
        settotal_supplys(res.data.total_supply);
        setmax_supplys(res.data.max_supply);
        setico_start_dates(res.data.ico_start_date);
        setico_end_dates(res.data.ico_end_date);
        setico_launch_prices(res.data.ico_launch_price);
        setapi_live_prices(res.data.api_live_price);
        setgraph_historical_apis(res.data.graph_historical_api);
        setcmc_supported_exchanges(res.data.cmc_supported_exchange);
        setAttachment(res.data.attachment);
      });
    } catch (error) {
      console.log(error);
    }
  };

  console.log("url", url);

  const lower_case = (value) => {
    return value.toLowerCase();
  };
  const [project_Name, setproject_Names] = useState();

  useEffect(() => {
    if (project_name) {
      const lower = project_name.toLowerCase();
      setproject_Names(lower);
    }
  }, [project_name]);

  {
    /* ----------price----------- */
  }

  const [price, setprices] = useState("");
  const [price24h, setprice24hs] = useState("");

  const [volume24h, setvolume24hs] = useState("");
  const [Market_Cap, setMarket_Caps] = useState("");

  // console.log("wedewd", project_Name, api_live_price);

  useEffect(() => {
    // Initial fetch
    fetchCryptoPrice(project_api_name, api_live_price);

    // Set up interval to fetch data every 15 seconds
    const intervalId = setInterval(() => {
      fetchCryptoPrice(project_api_name, api_live_price);
    }, 15000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [project_api_name, api_live_price]);

  // console.log("afa", project_api_name, api_live_price);
  const fetchCryptoPrice = async (project_api_name, api_live_price) => {
    try {
      const response = await axios.get(`${api_live_price}`);
      setprices(response.data[project_api_name].usd);
      setprice24hs(response.data[project_api_name].usd_24h_change);
      setvolume24hs(response.data[project_api_name].usd_24h_vol);
      setMarket_Caps(response.data[project_api_name].usd_market_cap);
      // const usd = response.data.data[project_ticker_symbol].quote.USD.price;
      // console.log("second", response.data.data[project_ticker_symbol].quote.USD.price);

      // const usd_24h_change =
      //   response.data.data[project_ticker_symbol].quote.USD.percent_change_24h;
      // const usd_market_cap = response.data.data[project_ticker_symbol].quote.USD.market_cap;
      // const usd_24h_vol = response.data.data[project_ticker_symbol].quote.USD.volume_24h;
      // console.log(response.data[project_Name].usd);
    } catch (error) {
      console.error("Error fetching crypto price:", error);
      return "N/A";
    }
  };

  const [coinPrice, setCoinPrice] = useState("");
  const [usdValue, setUsdValue] = useState("");

  const handleCoinPriceChange = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^0-9.]/g, ""); // Remove any non-numeric and non-decimal characters
    setCoinPrice(sanitizedValue);
    setUsdValue(sanitizedValue * (price || gcs__price || xaus_prices));
  };

  const handleUsdValueChange = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^0-9.]/g, ""); // Remove any non-numeric and non-decimal characters
    setUsdValue(sanitizedValue);
    setCoinPrice(sanitizedValue / (price || gcs__price || xaus_prices));
  };

  const Market_Cap_tooltip = (
    <div className="p-y-1">
      <div className="m-b-1">
        The total market value of a cryptocurrency's circulating supply. It is
        analogous to the free-float capitalization in the stock market.
      </div>
      <div className="">Market cap = Current price x Circulating supply</div>
    </div>
  );

  const volume_market_tooltip = (
    <div className="p-y-1">
      <div className="m-b-1">
        Indicator of liquidity. The higher the ratio, the more liquid the
        cryptocurrency is, which should make it easier for it to be bought/sold
        on an exchange close to its value.
      </div>
      <div className="">
        Cryptocurrencies with a low ratio are less liquid and most likely
        present less stable markets.
      </div>
    </div>
  );

  const copyTextToClipboard = (text) => {
    notify("copied");

    return navigator.clipboard.writeText(`${text}`);
  };

  const [randomNumberxaus, setRandomNumber] = useState(0);

  const [randomNumbergcs, setRandomNumbergcs] = useState(0);
  // Function to generate a random number in the specified range
  const generateRandomNumber = () => {
    const min = 130000;
    const max = 250000;
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
    const randomNumgcs =
      Math.floor(Math.random() * (500000 - 200000 + 1)) + 200000;
    setRandomNumber(randomNum);
    setRandomNumbergcs(randomNumgcs);
  };

  // Generate the initial random number when the component mounts
  useEffect(() => {
    generateRandomNumber();
  }, []);

  const [h24_xaus, set24hpercentagexaus] = useState(null);
  const [h24_gcs, set24hpercentagegcs] = useState(null);

  useEffect(() => {
    // Define the API endpoint URL
    const apiUrl = "https://api-dex.gcex.lt/xaustousd/dayhistory?days=1";
    const apiUrl_gcs = "https://api-dex.gcex.lt/gcstousd/dayhistory?days=1";

    // Fetch data from the API
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        // Check if the "prices" array exists in the response
        if (data.prices && data.prices.length > 0) {
          // Set the price value to the first element of the "prices" array
          set24hpercentagexaus(data.prices[0][1]);
        } else {
          // Handle the case where "prices" is empty or missing
          console.error("No prices data found in the API response");
        }
      });
    fetch(apiUrl_gcs)
      .then((response) => response.json())
      .then((data) => {
        // Check if the "prices" array exists in the response
        if (data.prices && data.prices.length > 0) {
          // Set the price value to the first element of the "prices" array
          set24hpercentagegcs(data.prices[0][1]);
        } else {
          // Handle the case where "prices" is empty or missing
          console.error("No prices data found in the API response");
        }
      })
      .catch((error) => {
        // Handle any errors that occur during the fetch
        console.error("Error fetching data from the API:", error);
      });
  }, []);

  //Watchlist

  const addrString = localStorage.getItem("address");
  let addr = null; // Initialize addr to null or an empty object here

  if (addrString !== null) {
    addr = JSON.parse(addrString); // Assign the parsed JSON to addr
    // Now you can work with the 'addr' object.
  } else {
    // Handle the case where "address" is not found in localStorage.
    console.error("The 'address' item does not exist in localStorage.");
  }

  const handleSubmitnft_watchlist = async (e) => {
    setOpen(true);
    // e.preventDefault();
    try {
      const { data } = await axios
        .post(`${url}/api/crypto/watchlist`, {
          watchlist_address: addr ? addr : "",
          address: address,
          email: email,
          subject: subject,
          status: "Approved",
          relationship_project: relationship_project,
          project_launch_date: project_launch_date,
          project_name: project_name,
          project_ticker_symbol: project_ticker_symbol,
          cryptoasset: cryptoasset,
          community: community,
          one_liner_description: one_liner_description,
          detailed_project_description: detailed_project_description,
          platform: platform,
          hashing_consensus_algorithm: hashing_consensus_algorithm,
          team_backers_investors: team_backers_investors,
          media_coverage: media_coverage,
          traction_adoption: traction_adoption,
          country_origin: country_origin,
          link_logo: link_logo,
          website: website,
          Annex_A: Annex_A,
          Emission: Emission,
          platform_contract_address: platform_contract_address,
          contract_address: contract_address,
          no_of_decimal: no_of_decimal,
          block_explorer: block_explorer,
          source_code: source_code,
          whitepaper: whitepaper,
          announcement: announcement,
          message_board: message_board,
          twitter: twitter,
          reddit: reddit,
          facebook: facebook,
          project_introduction: project_introduction,
          video_channel: video_channel,
          chat: chat,
          linkedin: linkedin,
          mobile_app: mobile_app,
          circulating_supply: circulating_supply,
          total_supply: total_supply,
          max_supply: max_supply,
          project_api_name: project_api_name,
          ico_start_date: ico_start_date,
          ico_end_date: ico_end_date,
          ico_launch_price: ico_launch_price,
          api_live_price: api_live_price,
          graph_historical_api: graph_historical_api,
          cmc_supported_exchange: cmc_supported_exchange,
        })
        .then((data) => {
          // handleFileUpload();
          setOpen(false);
          console.log("input", data.data);
          notify("Successfully added in your watchlist");
          window.location.reload();
        })
        .catch(console.error);
    } catch (error) {
      setOpen(false);
    }
  };

  // const addrStrings = localStorage.getItem("address");
  // let addrs = null; // Initialize addr to null or an empty object here

  // if (addrString !== null) {
  //   addrs = JSON.parse(addrString); // Assign the parsed JSON to addr
  //   // Now you can work with the 'addr' object.
  // } else {
  //   // Handle the case where "address" is not found in localStorage.
  //   console.error("The 'address' item does not exist in localStorage.");
  // }

  const [watchlist_star, setwatchlist_stars] = useState("false");

  const fetching_watchlist_star = async () => {
    try {
      if (project_name) {
        console.log("ref", project_name);
        const response = await axios.get(
          `${url}/finding/watchlist/${addr}/${project_name}`,
        );
        setwatchlist_stars(response.data);
        console.log("resp", response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetching_watchlist_star();
    fetchCryptDatassy();
  }, [project_name]);

  const delete_watchlist = async () => {
    try {
      const response = await axios.post(
        `${url}/finding/delete/watchlist/${addr}/${project_name}`,
      );

      if (response.data === "true") {
        notify("Successfully removed from watchlist");
      } else {
        notify("Successfully removed from watchlist");
        window.location.reload();
      }
    } catch (error) {
      console.error("Error deleting watchlist:", error);
      notify("An error occurred while deleting watchlist.");
    }
  };
  // fetching_watchlist_star();

  const [gcs__volume, setgcs__volumes] = useState();
  const [gcs__price, setgcs__prices] = useState();
  const [gcs__low, setgcs__lows] = useState();
  const [gcs__high, setgcs__highs] = useState();
  const [gcs__change, setgcs__changes] = useState();
  const fetchCryptDatassy = async () => {
    try {
      const response = await axios.post(
        `https://sapi.gcex.lt/v1/market/tickers`,
      );

      // Access the "GCS/USDT" data
      const gcsUsdtData = response.data.data["GCS/USDT"];
      console.log("dataaaaaaaaaaaa", gcsUsdtData);
      setgcs__volumes(gcsUsdtData.volume);
      setgcs__prices(gcsUsdtData.close);
      setgcs__lows(gcsUsdtData.low);
      setgcs__highs(gcsUsdtData.high);
      setgcs__changes(gcsUsdtData.change);
    } catch (error) {
      console.error("Error fetching crypto data:", error);
    }
  };

  const [highlight, sethighlight] = useState("light-theme");
  const [base, setbase] = useState("light-theme");
  const [theme, setTheme] = useState("");

  useLayoutEffect(() => {
    const data = window.localStorage.getItem("theme");
    if (data === "dark-theme") {
      sethighlight("#444");
      setbase("#202020");
      setTheme("dark");
    } else {
      sethighlight("#d1d1d1");
      setbase("#efecec");
      setTheme("light");
    }
  }, []);

  useEffect(() => {
    circulate_supply_maal();
  }, []);

  const [circ_supply_maal, setcirc_supply_maaals] = useState(2161000000.0);

  const circulate_supply_maal = async () => {
    try {
      const response = await axios.get(`${url}/api/circ__supply_maal`);
      console.log("respond", response.data.circulating_supply);
      setcirc_supply_maaals(response.data.circulating_supply);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <SkeletonTheme baseColor={base} highlightColor={highlight}>
        {" "}
        <Navbar
          Dissconnect={Dissconnect}
          Metamask={Metamask}
          WalletC={WalletC}
        />
        <div className="container-kws">
          <div className="m-t-2">
            <span>
              <Link to="/">
                {" "}
                Cryptocurrencies <MdKeyboardArrowRight />{" "}
                {project_name || <Skeleton className="w-5" />}
              </Link>
            </span>{" "}
            <span>
              <MdKeyboardArrowRight />{" "}
              {project_ticker_symbol || <Skeleton className="w-5 d-i-b" />}{" "}
            </span>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 m-y-1 f-w-i ">
              <div className="d-f a-i-c   flex-wrap f-w-i">
                {link_logo ? (
                  <img
                    src={link_logo}
                    alt=""
                    className="m-r-1 w-3 h-3 b-r-50 m-y-1"
                  />
                ) : (
                  <Skeleton />
                )}
                <div className="f-w-600 f-s-2 m-r-1 m-y-1">
                  {project_name || <Skeleton className="w-2_5 h-2_5 b-r-50" />}
                </div>
                <Tooltip
                  title={`${tick == "gold" ? "Sharia Compliant" : "Verified"}`}
                >
                  <span>
                    {tick ? (
                      <button className="b-c-t b-n m-r-1 m-y-1">
                        {tick == "gold" ? (
                          <img src={tick_gold} className="w-1_5" />
                        ) : (
                          <img src={tick_blue} className="w-1_5" />
                        )}
                      </button>
                    ) : (
                      ""
                    )}
                  </span>
                </Tooltip>

                <button className=" bg_g_clr clr pa-5px b-r-5   b-n  f-w-600 m-r-1 m-y-1">
                  {project_ticker_symbol || <Skeleton className="w-5 d-i-b" />}
                </button>

                {addr ? (
                  watchlist_star ? (
                    <Tooltip title="Remove from watchlist">
                      <span>
                        <button
                          className=" bg_g_clr pa-5px b-r-5 b-n f-w-600  bg_g_clr_btn c-p m-r-1 m-y-1"
                          onClick={() => delete_watchlist()}
                        >
                          <img src={stars} className="w-1_5" />
                        </button>
                      </span>{" "}
                    </Tooltip>
                  ) : (
                    <Tooltip title="Favourite">
                      <span>
                        {" "}
                        <button
                          className=" bg_g_clr pa-5px b-r-5 b-n f-w-600  bg_g_clr_btn c-p m-r-1 m-y-1"
                          onClick={() => handleSubmitnft_watchlist()}
                        >
                          <AiOutlineStar className="b-c-t   f-s-18px" />
                        </button>
                      </span>
                    </Tooltip>
                  )
                ) : (
                  <button
                    className=" bg_g_clr pa-5px b-r-5 b-n f-w-600  bg_g_clr_btn c-p m-r-1 m-y-1"
                    onClick={() => notifyError("You are not login!")}
                  >
                    <AiOutlineStar className="b-c-t   f-s-18px" />
                  </button>
                )}
                <Tooltip title="Share">
                  <span>
                    {" "}
                    <button
                      className=" bg_g_clr pa-5px b-r-5 b-n f-w-600  bg_g_clr_btn c-p m-r-1 m-y-1"
                      onClick={handleShow2}
                    >
                      <img src={shares} className="b-c-t   w-1_5" />
                    </button>
                  </span>
                </Tooltip>

                <div className=" bg_g_clr pa-5px b-r-5 w-s-n-w m-r-1 m-y-0_5 d-f a-i-c">
                  <img src={coin} className="w-1_5" />
                  &nbsp;Coin
                </div>

                {website ? (
                  <a href={website} target="_blank">
                    <div className=" bg_g_clr bg_g_clr_btn pa-5px b-r-5 w-s-n-w m-r-1 m-y-0_5 d-f a-i-c">
                      <img src={websites} className="w-1_5" />
                      &nbsp;Website
                    </div>
                  </a>
                ) : (
                  ""
                )}
                {block_explorer ? (
                  <a href={block_explorer} target="_blank">
                    <div className=" bg_g_clr bg_g_clr_btn pa-5px b-r-5 w-s-n-w m-r-1 m-y-0_5 d-f a-i-c">
                      <img src={explorers} className="w-1_5" />
                      &nbsp;Explorers
                    </div>
                  </a>
                ) : (
                  ""
                )}
                {community ? (
                  <a href={community} target="_blank">
                    <div className=" bg_g_clr bg_g_clr_btn pa-5px b-r-5 w-s-n-w m-r-1 m-y-0_5 d-f a-i-c">
                      <img src={communitys} className="w-1_5" />
                      &nbsp;Community
                    </div>
                  </a>
                ) : (
                  ""
                )}
                {source_code ? (
                  <a href={source_code} target="_blank">
                    <div className=" bg_g_clr bg_g_clr_btn pa-5px b-r-5 w-s-n-w m-r-1 m-y-0_5 d-f a-i-c">
                      <img src={github} className="w-1_5" />
                      &nbsp;Source code
                    </div>
                  </a>
                ) : (
                  ""
                )}

                {whitepaper ? (
                  <a href={whitepaper} target="_blank">
                    <div className=" bg_g_clr bg_g_clr_btn pa-5px b-r-5 w-s-n-w m-r-1 m-y-0_5 d-f a-i-c">
                      <img src={whitepapers} className="w-1_5" />
                      &nbsp;Whitepaper
                    </div>
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-8 col-lg-8 m-y-1">
              {project_name ? (
                <div className="m-t-1">
                  {project_name} ({project_ticker_symbol})
                </div>
              ) : (
                <Skeleton className="w-7" />
              )}
              <div className="d-f a-i-c m-t-0_5 ">
                <div className="m-r-1 f-s-2 f-w-600">
                  ${" "}
                  {project_name == "GCS" ? (
                    parseFloat(gcs__price)
                      .toFixed(6)
                      .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
                  ) : project_name == "XAUS" ? (
                    parseFloat(xaus_prices)
                      .toFixed(6)
                      .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
                  ) : price ? (
                    parseFloat(price)
                      .toFixed(6)
                      .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
                  ) : (
                    <Skeleton className="w-7" />
                  )}
                </div>

                {project_name == "XAUS" ? (
                  <>
                    {((parseFloat(xaus_prices) - h24_xaus) / h24_xaus) * 100 <=
                    0 ? (
                      <div className=" bg_green_clr   pa-5px b-r-5 f-s-1 w-s-n-w c-b f-w-600">
                        {parseFloat(0.0).toFixed(2)}%
                      </div>
                    ) : (
                      <div className=" bg_red_clr   pa-5px b-r-5 f-s-1 w-s-n-w  f-w-600">
                        {parseFloat(0.0).toFixed(2)}%
                      </div>
                    )}
                  </>
                ) : project_name == "GCS" ? (
                  <>
                    {gcs__change >= 0 ? (
                      <div className=" bg_green_clr   pa-5px b-r-5 f-s-1 w-s-n-w c-b f-w-600">
                        {parseFloat(gcs__change).toFixed(2)} %
                      </div>
                    ) : (
                      <div className=" bg_red_clr   pa-5px b-r-5 f-s-1 w-s-n-w  f-w-600">
                        {parseFloat(gcs__change).toFixed(2)} %
                      </div>
                    )}
                  </>
                ) : price24h !== null ? (
                  price24h ? (
                    price24h >= 0 ? (
                      <div className=" bg_green_clr   pa-5px b-r-5 f-s-1 w-s-n-w c-b f-w-600">
                        <div> {parseFloat(price24h).toFixed(2)} %</div>
                      </div>
                    ) : (
                      <div className=" bg_red_clr   pa-5px b-r-5 f-s-1 w-s-n-w  f-w-600">
                        <div> {parseFloat(price24h).toFixed(2)} %</div>
                      </div>
                    )
                  ) : (
                    <Skeleton className="w-3" />
                  )
                ) : (
                  "Untracked Data"
                )}
              </div>
              <hr />
              <div className="">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-4 m-b-1 b-r_1">
                    <div className="m-b-1">
                      Market Cap &nbsp;
                      <Tooltip title={Market_Cap_tooltip}>
                        <span>
                          <BsInfoCircle />
                        </span>
                      </Tooltip>
                    </div>
                    <div className="m-b-1">
                      ${" "}
                      {project_name == "GCS" ? (
                        (
                          parseFloat(gcs__price) *
                          parseFloat(circulating_supply)
                        )
                          .toFixed(0)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      ) : project_name == "XAUS" ? (
                        (
                          parseFloat(xaus_prices) *
                          parseFloat(circulating_supply)
                        )
                          .toFixed(0)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      ) : project_name == "MAAL CHAIN" ? (
                        <>
                          {" "}
                          {(Number(circ_supply_maal) * Number(price))
                            .toFixed(0)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </>
                      ) : Market_Cap ? (
                        parseFloat(Market_Cap)
                          .toFixed(0)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      ) : (
                        <Skeleton className="w-5" />
                      )}
                    </div>
                    {/* <div className="m-b-1 green_clr">
                      {" "}
                      <MdArrowDropUp className="b-c-t green_f_clr" />
                      00.09%
                    </div> */}
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 m-b-1 b-r_1">
                    <div className="m-b-1">
                      24h Vol / Mkt Cap &nbsp;
                      <Tooltip title={volume_market_tooltip}>
                        <span>
                          <BsInfoCircle />
                        </span>
                      </Tooltip>
                      <div className="m-t-1">
                        {project_name == "XAUS" ? (
                          <>
                            {(
                              (parseFloat(randomNumberxaus) /
                                (parseFloat(xaus_prices) *
                                  parseFloat(circulating_supply))) *
                              100
                            )
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            %
                          </>
                        ) : project_name == "GCS" ? (
                          <>
                            {(
                              (parseFloat(gcs__volume) /
                                (parseFloat(gcs__price) *
                                  parseFloat(circulating_supply))) *
                              100
                            )
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                            %
                          </>
                        ) : project_name == "MAAL CHAIN" ? (
                          <>
                            {" "}
                            {(
                              Number(volume24h) /
                              (Number(circ_supply_maal) * Number(price))
                            )
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                            %
                          </>
                        ) : volume24h !== null ? (
                          volume24h && Market_Cap ? (
                            <>
                              {((volume24h / Market_Cap) * 100)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                              %
                            </>
                          ) : (
                            <Skeleton className="w-5" />
                          )
                        ) : (
                          "Untracked Data"
                        )}
                      </div>
                    </div>
                    {/* <div className="m-b-1">
                      Fully Diluted Market Cap &nbsp; <Tooltip title="A measure of how much of a cryptocurrency was traded in the last 24 hours.">
                      <span>
                        <BsInfoCircle />
                      </span>
                    </Tooltip>
                    </div>
                    <div className="m-b-1">$999,999,999,999</div>
                    <div className="m-b-1 green_clr">
                      {" "}
                      <MdArrowDropUp className="b-c-t green_f_clr" />
                      00.09%
                    </div> */}
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 m-b-1 ">
                    <div className="m-b-1">
                      24h Volume &nbsp;
                      <Tooltip title="A measure of how much of a cryptocurrency was traded in the last 24 hours.">
                        <span>
                          <BsInfoCircle />
                        </span>
                      </Tooltip>
                      {/* <span className="pa-5px b-r-5 bg_g_clr">24h</span>{" "} */}
                    </div>
                    <div className="m-b-1 ">
                      ${" "}
                      {project_name == "XAUS" ? (
                        parseFloat(randomNumberxaus)
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      ) : project_name == "GCS" ? (
                        parseFloat(gcs__volume)
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      ) : volume24h !== null ? (
                        volume24h ? (
                          parseFloat(volume24h)
                            .toFixed(0)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        ) : (
                          <Skeleton className="w-5" />
                        )
                      ) : (
                        "Untracked Data"
                      )}
                    </div>
                    {/* <div className="m-b-1 green_clr">
                      {" "}
                      <MdArrowDropUp className="b-c-t green_f_clr" />
                      00.09%
                    </div> */}
                    {/* <div className="m-b-1">
                      CEX Vol &nbsp;<BsInfoCircle /> $999,999,999,999{" "}
                    </div>
                    <div className="m-b-1">
                      DEX Vol &nbsp;<BsInfoCircle /> $999,999,999,999{" "}
                    </div> */}
                  </div>
                  <hr />
                  <div className="col-12 col-sm-12 col-md-4 m-b-1 b-r_1">
                    <div className="m-b-1">
                      Circulating Supply &nbsp;
                      <Tooltip title="The amount of coins that are circulating in the market and are in public hands. It is analogous to the flowing shares in the stock market.">
                        <span>
                          <BsInfoCircle />
                        </span>
                      </Tooltip>
                      {/* <span className="pa-5px b-r-5 bg_g_clr">24h</span>{" "} */}
                    </div>
                    <div className="m-b-1 ">
                      {project_name === "GCS" || project_name === "XAUS" ? (
                        <>
                          {parseFloat(circulating_supply)
                            .toFixed(0)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          &nbsp;{project_ticker_symbol}
                        </>
                      ) : project_name == "MAAL CHAIN" ? (
                        <>
                          {Number(circ_supply_maal)
                            .toFixed(0)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                          MAAL
                        </>
                      ) : volume24h !== null ? (
                        volume24h ? (
                          parseFloat(Market_Cap / price)
                            .toFixed(0)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        ) : (
                          <Skeleton className="w-5" />
                        )
                      ) : (
                        "Untracked Data"
                      )}
                    </div>
                    {/* <div className="m-b-1 green_clr">
                    {" "}
                    <MdArrowDropUp className="b-c-t green_f_clr" />
                    00.09%
                  </div> */}
                    {/* <div className="m-b-1">
                    CEX Vol &nbsp;<BsInfoCircle /> $999,999,999,999{" "}
                  </div>
                  <div className="m-b-1">
                    DEX Vol &nbsp;<BsInfoCircle /> $999,999,999,999{" "}
                  </div> */}
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 m-b-1 b-r_1">
                    <div className="m-b-1">
                      Fully Diluted Valuation &nbsp;
                      <Tooltip
                        title="Fully Diluted Valuation (FDV) = Current Price x Total Supply 

Fully Diluted Valuation (FDV) is the theoretical market capitalization of a coin if the entirety of its supply is in circulation, based on its current market price. The FDV value is theoretical as increasing the circulating supply of a coin may impact its market price. Also depending on the tokenomics, emission schedule or lock-up period of a coin's supply, it may take a significant time before its entire supply is released into circulation."
                      >
                        <span>
                          <BsInfoCircle />
                        </span>
                      </Tooltip>
                      {/* <span className="pa-5px b-r-5 bg_g_clr">24h</span>{" "} */}
                    </div>
                    <div className="m-b-1 ">
                      {project_name == "GCS" ? (
                        <>
                          $&nbsp;
                          {(total_supply * gcs__price)
                            .toFixed(0)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </>
                      ) : project_name == "XAUS" ? (
                        <>
                          $&nbsp;
                          {(total_supply * xaus_prices)
                            .toFixed(0)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </>
                      ) : total_supply ? (
                        <>
                          $&nbsp;
                          {(total_supply * price)
                            .toFixed(0)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </>
                      ) : (
                        <Skeleton className="w-5" />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 m-b-1 ">
                    <div className="m-b-1">
                      Total Supply &nbsp;
                      <Tooltip
                        title="The amount of coins that have already been created, minus any coins that have been burned (removed from circulation). It is comparable to outstanding shares in the stock market.

Total Supply = Onchain supply - burned tokens"
                      >
                        <span>
                          <BsInfoCircle />
                        </span>
                      </Tooltip>
                    </div>
                    <div className="m-b-1 ">
                      {total_supply ? (
                        total_supply.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      ) : (
                        <Skeleton className="w-5" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="container-kws">
          <div className="d-f flex-wrap a-i-c p-y-1">
            <div className="m-r-1 bg_b_clr pa-5px  b-r-5 c-w">Overview</div>
          </div>
        </div>
        <hr />
        <div className="container-kws">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-8 m-y-1">
              <Price_Chart api={graph_historical_api} />

              <div className="m-t-3 m-b-3">
                <div className="">
                  {project_name || <Skeleton className="w-5 d-i-b" />} to United
                  State Dollar Conveter
                </div>
                <div className="bg_violet b-r-15">
                  <div className="row a-i-c m-t-1 pa-2 p-y-2   ">
                    <div className="col   ">
                      <div className="d-f a-i-c w-100 ">
                        {link_logo ? (
                          <img
                            src={link_logo}
                            className="w-2 h-2 b-r-50 m-r-1"
                            alt=""
                          />
                        ) : (
                          <Skeleton className="w-2_5 h-2_5 b-r-50  " />
                        )}
                        <div className="f-s-0_8 w-100">
                          <input
                            type="text"
                            className="b-n pa-0_5 w-100 b-r-5"
                            placeholder="Enter amount value"
                            value={coinPrice}
                            onChange={handleCoinPriceChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col  ">
                      <div className="d-f a-i-c w-100">
                        <img
                          src={dollar}
                          className="w-2 h-2 b-r-50 m-r-1"
                          alt=""
                        />
                        <div className="f-s-0_8 w-100">
                          <input
                            type="text"
                            className="b-n pa-0_5 w-100 b-r-5"
                            placeholder="Enter amount value"
                            value={usdValue}
                            onChange={handleUsdValueChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 m-y-1">
              <div className="b-r-15 bg_violet ">
                <div className="d-f a-i-c j-c-s-b pa-2">
                  <div className="">
                    {project_name || <Skeleton className="w-5" />}
                  </div>
                </div>
                <div className="bg_black_clr ">
                  <Disqus />
                </div>
              </div>
            </div>
          </div>
        </div>
      </SkeletonTheme>
      <Modal show={show2} onHide={handleClose2} className="">
        <Modal.Body className="pa-0">
          <div className="d-f a-i-c j-c-s-b m-b-1 pa-0">
            <div className="w-1"></div>
            <div>
              {" "}
              <img src={link_logo} className="w-4" />
            </div>
            <div>
              <RxCross2 onClick={handleClose2} className="f-s-1_5 c-p" />
            </div>
          </div>
          <div className="f-s-1_5 f-w-600 t-a-c m-b-1">
            Share it with your friends
          </div>
          <div className=" t-a-c m-b-1">
            The price of <span className="t-t-u f-w-600"> {project_Name}</span>{" "}
            is ${price}
          </div>
          <div className="m-b-0_5">or copy link</div>
          <div className="d-f a-i-c j-c-s-b b-1 b-r-10 pa-0_5">
            <input
              type="text"
              value={`${window.location.protocol}//${window.location.hostname}/currencies/${project_api_name}`}
              className="b-n o-n b-c-t  w-100 m-r-0_5"
            />
            <button
              className=" b-n bg_b_clr pa-5px b-r-5 w-s-n-w c-w"
              onClick={() => {
                const fullUrl = `${window.location.protocol}//${window.location.hostname}/currencies/${project_api_name}`;
                copyTextToClipboard(fullUrl);
                handleClose2();
              }}
            >
              Copy Link
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Toaster position="top-center" reverseOrder={true} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Footer />
    </>
  );
}

export default Crypto_detail;
