import React, { useEffect, useLayoutEffect, useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";

import { AiTwotoneCheckCircle } from "react-icons/ai";
import { Modal } from "react-bootstrap";
import shares from "../Image/shares.png";
import { RxCross2 } from "react-icons/rx";
import reddits from "../Image/reddit.png";
import telegrams from "../Image/telegram.png";
import websites from "../Image/website.png";
import facebooks from "../Image/facebook.png";
import Footer from "./Footer";
import Navbar from "./Navbar";
import Price_Exchange_Chart from "./Price_Exchange_Chart";
import { Tooltip } from "antd";
import { Link, useParams } from "react-router-dom";
import tick_blue from "../Image/tick_blue.png";
import tick_gold from "../Image/tick_gold.png";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Disqus from "./Disqus";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {
  Backdrop,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Image_api from "./Image_api";

const notify = (msg) => toast.success(msg);
const notifyError = (msg) => toast.error(msg);
const notifyMessage = (msg) =>
  toast(msg, {
    duration: 10000,
  });

function Crypto_detail({ url, Dissconnect, Metamask, WalletC }) {
  const [h_24, seth_24s] = useState(8);

  const [remark, setremarks] = useState();

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const { project_api_named } = useParams();
  console.log("wf", project_api_named);

  // console.log("a", id);

  const [value, setvalues] = useState();
  const [email, setemails] = useState();
  const [subject, setsubjects] = useState();
  const [Review, setReviews] = useState();
  const [Terms, setTermss] = useState();
  const [Declaration, setDeclarations] = useState();
  const [Disclaimer, setDisclaimers] = useState();
  const [relationship_project, setrelationship_projects] = useState();
  const [project_launch_date, setproject_launch_dates] = useState();
  const [project_name, setproject_names] = useState();
  const [project_ticker_symbol, setproject_ticker_symbols] = useState();
  const [cryptoasset, setcryptoassets] = useState();
  const [one_liner_description, setone_liner_descriptions] = useState();
  const [detailed_project_description, setdetailed_project_descriptions] =
    useState();
  const [platform, setplatforms] = useState();
  const [hashing_consensus_algorithm, sethashing_consensus_algorithms] =
    useState();
  const [team_backers_investors, setteam_backers_investorss] = useState();
  const [media_coverage, setmedia_coverages] = useState();
  const [traction_adoption, settraction_adoptions] = useState();
  const [country_origin, setcountry_origins] = useState();
  const [link_logo, setlink_logos] = useState();
  const [website, setwebsites] = useState();
  const [Annex_A, setAnnex_As] = useState();
  const [Emission, setEmissions] = useState();
  const [platform_contract_address, setplatform_contract_addresss] = useState();
  const [contract_address, setcontract_addresss] = useState();
  const [no_of_decimal, setno_of_decimals] = useState();
  const [block_explorer, setblock_explorers] = useState();
  const [source_code, setsource_codes] = useState();
  const [whitepaper, setwhitepapers] = useState();
  const [announcement, setannouncements] = useState();
  const [message_board, setmessage_boards] = useState();
  const [project_api_name, setproject_api_names] = useState();
  const [twitter, settwitters] = useState();
  const [reddit, setreddits] = useState();
  const [telegram, settelegrams] = useState();
  const [facebook, setfacebooks] = useState();
  const [project_introduction, setproject_introductions] = useState();
  const [video_channel, setvideo_channels] = useState();
  const [chat, setchats] = useState();
  const [linkedin, setlinkedins] = useState();
  const [mobile_app, setmobile_apps] = useState();
  const [circulating_supply, setcirculating_supplys] = useState();
  const [total_supply, settotal_supplys] = useState();
  const [max_supply, setmax_supplys] = useState();
  const [ico_start_date, setico_start_dates] = useState();
  const [ico_end_date, setico_end_dates] = useState();
  const [ico_launch_price, setico_launch_prices] = useState();
  const [api_live_price, setapi_live_prices] = useState();
  const [graph_historical_api, setgraph_historical_apis] = useState();
  const [cmc_supported_exchange, setcmc_supported_exchanges] = useState();
  const [attachment, setAttachment] = useState(null);
  const [address, setAddress] = useState();
  const [community, setcommunitys] = useState();
  const [open, setOpen] = useState(false);
  const [tick, setticks] = useState();

  const [gcs_prices, setgcs_pricess] = useState(null);
  const [xaus_prices, setxaus_pricess] = useState(null);

  useEffect(() => {
    const fetchDatassY = async () => {
      try {
        const response = await axios.get(
          "https://api-dex.gcex.lt/xaustousd/live",
        );

        setxaus_pricess(response.data.XAUS.usd);
        // setprices(response.data.XAUS.usd);
      } catch (error) {
        console.error("Error fetching crypto data:", error);
      }
    };

    fetchDatassY();
  }, []);

  useEffect(() => {
    const fetchDatass = async () => {
      try {
        const response = await axios.get(
          "https://api-dex.gcex.lt/gcstousd/live",
        );
        // const responseData = response.data.data;
        console.log("ef8454", response.data.GCS.usd);

        setgcs_pricess(response.data.GCS.usd);
        // setprices(response.data.GCS.usd);
      } catch (error) {
        console.error("Error fetching crypto data:", error);
      }
    };

    fetchDatass();
  }, []);

  // const [coinPrice, setcoinPrices] = useState("1");
  const [usd, setusds] = useState();
  const [description, setDescriptions] = useState("");

  useEffect(() => {
    PackagesDetails();
  }, []);

  const [crypt, setcryptos] = useState([]);

  const PackagesDetails = () => {
    try {
      axios.get(`${url}/api/exchanges/${project_api_named}`).then((res) => {
        console.log("gettingpacakgecarddetails", res.data);
        setemails(res.data.email);
        setAddress(res.data.address);
        setcommunitys(res.data.community);
        setDescriptions(res.data.detailed_project_description);
        setsubjects(res.data.subject);
        setReviews(res.data.Review);
        setTermss(res.data.Terms);
        setticks(res.data.tick);
        setDeclarations(res.data.Declaration);
        setDisclaimers(res.data.Disclaimer);
        setrelationship_projects(res.data.relationship_project);
        setproject_launch_dates(res.data.project_launch_date);
        setproject_names(res.data.project_name);
        setproject_api_names(res.data.project_api_name);
        setproject_ticker_symbols(res.data.project_ticker_symbol);
        setcryptoassets(res.data.cryptoasset);
        setone_liner_descriptions(res.data.one_liner_description);
        setdetailed_project_descriptions(res.data.detailed_project_description);
        setplatforms(res.data.platform);
        sethashing_consensus_algorithms(res.data.hashing_consensus_algorithm);
        setteam_backers_investorss(res.data.team_backers_investors);
        setmedia_coverages(res.data.media_coverage);
        settraction_adoptions(res.data.traction_adoption);
        setcountry_origins(res.data.country_origin);
        setlink_logos(res.data.link_logo);
        setwebsites(res.data.website);
        setAnnex_As(res.data.Annex_A);
        setEmissions(res.data.Emission);
        setplatform_contract_addresss(res.data.platform_contract_address);
        setcontract_addresss(res.data.contract_address);
        setno_of_decimals(res.data.no_of_decimal);
        setblock_explorers(res.data.block_explorer);
        setsource_codes(res.data.source_code);
        setwhitepapers(res.data.whitepaper);
        setannouncements(res.data.announcement);
        setmessage_boards(res.data.message_board);
        settwitters(res.data.twitter);
        setreddits(res.data.reddit);
        setfacebooks(res.data.facebook);
        setproject_introductions(res.data.project_introduction);
        setvideo_channels(res.data.video_channel);
        setchats(res.data.chat);
        setlinkedins(res.data.linkedin);
        setmobile_apps(res.data.mobile_app);
        setcirculating_supplys(res.data.circulating_supply);
        settotal_supplys(res.data.total_supply);
        setmax_supplys(res.data.max_supply);
        setico_start_dates(res.data.ico_start_date);
        setico_end_dates(res.data.ico_end_date);
        setico_launch_prices(res.data.ico_launch_price);
        setapi_live_prices(res.data.api_live_price);
        setgraph_historical_apis(res.data.graph_historical_api);
        setcmc_supported_exchanges(res.data.cmc_supported_exchange);
        setAttachment(res.data.attachment);
      });
    } catch (error) {
      console.log(error);
    }
  };

  console.log("url", url);

  const lower_case = (value) => {
    return value.toLowerCase();
  };
  const [project_Name, setproject_Names] = useState();

  useEffect(() => {
    if (project_name) {
      const lower = project_name.toLowerCase();
      setproject_Names(lower);
    }
  }, [project_name]);

  {
    /* ----------price----------- */
  }

  const [price, setprices] = useState("");
  const [price24h, setprice24hs] = useState("");

  const [volume24h, setvolume24hs] = useState("");
  const [Market_Cap, setMarket_Caps] = useState("");

  const [site, setsites] = useState("");
  const [ticker, settickers] = useState([]);

  // console.log("wedewd", project_Name, api_live_price);

  useEffect(() => {
    fetchCryptoPrice(project_api_name, api_live_price);
  }, [project_api_name, api_live_price]);
  console.log("afa", project_api_name, api_live_price);
  const fetchCryptoPrice = async (project_api_name, api_live_price) => {
    try {
      const response = await axios.get(`${api_live_price}`);
      console.log("wef", response);
      setprices(response.data.trust_score);
      setfacebooks(response.data.facebook_url);
      settickers(response.data.tickers);
      settelegrams(response.data.telegram_url);
      setreddits(response.data.reddit_url);
      setsites(response.data.url);
      setvolume24hs(response.data.trade_volume_24h_btc);
      setMarket_Caps(response.data.trade_volume_24h_btc_normalized);
    } catch (error) {
      console.error("Error fetching crypto price:", error);
      return "N/A";
    }
  };
  console.log("ticker", ticker);
  const [coinPrice, setCoinPrice] = useState("");
  const [usdValue, setUsdValue] = useState("");

  const handleCoinPriceChange = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^0-9.]/g, ""); // Remove any non-numeric and non-decimal characters
    setCoinPrice(sanitizedValue);
    setUsdValue(sanitizedValue * (price || gcs__price || xaus_prices));
  };

  const handleUsdValueChange = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^0-9.]/g, ""); // Remove any non-numeric and non-decimal characters
    setUsdValue(sanitizedValue);
    setCoinPrice(sanitizedValue / (price || gcs__price || xaus_prices));
  };

  const Market_Cap_tooltip = (
    <div className="p-y-1">
      <div className="m-b-1">
        The total market value of a cryptocurrency's circulating supply. It is
        analogous to the free-float capitalization in the stock market.
      </div>
      <div className="">Market cap = Current price x Circulating supply</div>
    </div>
  );

  const volume_market_tooltip = (
    <div className="p-y-1">
      <div className="m-b-1">
        Indicator of liquidity. The higher the ratio, the more liquid the
        cryptocurrency is, which should make it easier for it to be bought/sold
        on an exchange close to its value.
      </div>
      <div className="">
        Cryptocurrencies with a low ratio are less liquid and most likely
        present less stable markets.
      </div>
    </div>
  );

  const copyTextToClipboard = (text) => {
    notify("copied");

    return navigator.clipboard.writeText(`${text}`);
  };

  const [randomNumberxaus, setRandomNumber] = useState(0);

  const [randomNumbergcs, setRandomNumbergcs] = useState(0);
  // Function to generate a random number in the specified range
  const generateRandomNumber = () => {
    const min = 130000;
    const max = 250000;
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
    const randomNumgcs =
      Math.floor(Math.random() * (500000 - 200000 + 1)) + 200000;
    setRandomNumber(randomNum);
    setRandomNumbergcs(randomNumgcs);
  };

  // Generate the initial random number when the component mounts
  useEffect(() => {
    generateRandomNumber();
  }, []);

  const [h24_xaus, set24hpercentagexaus] = useState(null);
  const [h24_gcs, set24hpercentagegcs] = useState(null);

  useEffect(() => {
    // Define the API endpoint URL
    const apiUrl = "https://api-dex.gcex.lt/xaustousd/dayhistory?days=1";
    const apiUrl_gcs = "https://api-dex.gcex.lt/gcstousd/dayhistory?days=1";

    // Fetch data from the API
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        // Check if the "prices" array exists in the response
        if (data.prices && data.prices.length > 0) {
          // Set the price value to the first element of the "prices" array
          set24hpercentagexaus(data.prices[0][1]);
        } else {
          // Handle the case where "prices" is empty or missing
          console.error("No prices data found in the API response");
        }
      });
    fetch(apiUrl_gcs)
      .then((response) => response.json())
      .then((data) => {
        // Check if the "prices" array exists in the response
        if (data.prices && data.prices.length > 0) {
          // Set the price value to the first element of the "prices" array
          set24hpercentagegcs(data.prices[0][1]);
        } else {
          // Handle the case where "prices" is empty or missing
          console.error("No prices data found in the API response");
        }
      })
      .catch((error) => {
        // Handle any errors that occur during the fetch
        console.error("Error fetching data from the API:", error);
      });
  }, []);

  //Watchlist

  // const addrString = localStorage.getItem("address");
  // let addr = null; // Initialize addr to null or an empty object here

  // if (addrString !== null) {
  //   addr = JSON.parse(addrString); // Assign the parsed JSON to addr
  //   // Now you can work with the 'addr' object.
  // } else {
  //   // Handle the case where "address" is not found in localStorage.
  //   console.error("The 'address' item does not exist in localStorage.");
  // }

  useEffect(() => {
    fetchCryptDatassy();
  }, [project_name]);

  // const delete_watchlist = async () => {
  //   try {
  //     const response = await axios.post(
  //       `${url}/finding/delete/watchlist/${addr}/${project_name}`
  //     );

  //     if (response.data === "true") {
  //       notify("Successfully removed from watchlist");
  //     } else {
  //       notify("Successfully removed from watchlist");
  //       window.location.reload();
  //     }
  //   } catch (error) {
  //     console.error("Error deleting watchlist:", error);
  //     notify("An error occurred while deleting watchlist.");
  //   }
  // };
  // fetching_watchlist_star();

  const [gcs__volume, setgcs__volumes] = useState();
  const [gcs__price, setgcs__prices] = useState();
  const [gcs__low, setgcs__lows] = useState();
  const [gcs__high, setgcs__highs] = useState();
  const [gcs__change, setgcs__changes] = useState();
  const fetchCryptDatassy = async () => {
    try {
      const response = await axios.post(
        `https://sapi.gcex.lt/v1/market/tickers`,
      );

      // Access the "GCS/USDT" data
      const gcsUsdtData = response.data.data["GCS/USDT"];
      console.log("dataaaaaaaaaaaa", gcsUsdtData);
      setgcs__volumes(gcsUsdtData.volume);
      setgcs__prices(gcsUsdtData.close);
      setgcs__lows(gcsUsdtData.low);
      setgcs__highs(gcsUsdtData.high);
      setgcs__changes(gcsUsdtData.change);
    } catch (error) {
      console.error("Error fetching crypto data:", error);
    }
  };

  const [highlight, sethighlight] = useState("light-theme");
  const [base, setbase] = useState("light-theme");
  const [theme, setTheme] = useState("light");

  useLayoutEffect(() => {
    const data = window.localStorage.getItem("theme");
    if (data === "dark-theme") {
      sethighlight("#444");
      setbase("#202020");
      setTheme("dark");
    } else {
      sethighlight("#d1d1d1");
      setbase("#efecec");
      setTheme("light");
    }
  }, []);

  const [btc_price, setbtc_prices] = useState();

  useEffect(() => {
    bitcoin_price_fetch();
    // requestData();
  }, []);

  const bitcoin_price_fetch = async () => {
    try {
      const response = await axios.get(
        `https://pro-api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd&precision=4&x_cg_pro_api_key=CG-VTWbFPyR2KdGKX9LPTiPFBWW`,
      );
      setbtc_prices(response?.data?.bitcoin?.usd);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <SkeletonTheme baseColor={base} highlightColor={highlight}>
        {" "}
        <Navbar
          Dissconnect={Dissconnect}
          Metamask={Metamask}
          WalletC={WalletC}
        />
        <div className="container-kws">
          <div className="m-t-2">
            <span>
              <Link to="/exchanges">
                {" "}
                Crypto Exchanges <MdKeyboardArrowRight />{" "}
                {project_name || <Skeleton className="w-5" />}
              </Link>
            </span>{" "}
            {project_ticker_symbol ? (
              <span>
                <MdKeyboardArrowRight />{" "}
                {project_ticker_symbol || <Skeleton className="w-5 d-i-b" />}{" "}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 m-y-1 f-w-i ">
              <div className="d-f a-i-c   flex-wrap f-w-i">
                {link_logo ? (
                  <img
                    src={link_logo}
                    alt=""
                    className="m-r-1 w-3 h-3 b-r-50 m-y-1"
                  />
                ) : (
                  <Skeleton />
                )}
                <div className="f-w-600 f-s-2 m-r-1 m-y-1">
                  {project_name || <Skeleton className="w-2_5 h-2_5 b-r-50" />}
                </div>
                <Tooltip
                  title={`${tick == "gold" ? "Sharia Complaint" : "Verified"}`}
                >
                  <span>
                    {tick ? (
                      <button className="b-c-t b-n m-r-1 m-y-1">
                        {tick == "gold" ? (
                          <img src={tick_gold} className="w-1_5" />
                        ) : (
                          <img src={tick_blue} className="w-1_5" />
                        )}
                      </button>
                    ) : (
                      ""
                    )}
                  </span>
                </Tooltip>
                {project_ticker_symbol ? (
                  <button className=" bg_g_clr pa-5px b-r-5 clr  b-n  f-w-600 m-r-1 m-y-1">
                    {project_ticker_symbol || (
                      <Skeleton className="w-5 d-i-b" />
                    )}
                  </button>
                ) : (
                  ""
                )}

                {/*  <span className="m-r-1  ">
                  <AiFillStar className="b-c-t b-1 b_clr_gray b-r-5 pa-5px  f-s-1_5" />
                </span> */}

                <Tooltip title="Share">
                  <span>
                    {" "}
                    <button
                      className=" bg_g_clr pa-5px b-r-5 b-n f-w-600  bg_g_clr_btn c-p m-r-1 m-y-1"
                      onClick={handleShow2}
                    >
                      <img src={shares} className="b-c-t   w-1_5" />
                    </button>
                  </span>
                </Tooltip>

                {/* <div className=" bg_g_clr pa-5px b-r-5 w-s-n-w m-r-1 m-y-0_5 d-f a-i-c">
                  <img src={coin} className="w-1_5" />
                  &nbsp;Coin
                </div> */}

                {site ? (
                  <a href={site} target="_blank">
                    <div className=" bg_g_clr bg_g_clr_btn pa-5px b-r-5 w-s-n-w m-r-1 m-y-0_5 d-f a-i-c">
                      <img src={websites} className="w-1_5" />
                      &nbsp;Start Trading
                    </div>
                  </a>
                ) : (
                  ""
                )}
                {facebook ? (
                  <a href={facebook} target="_blank">
                    <div className=" bg_g_clr bg_g_clr_btn pa-5px b-r-5 w-s-n-w m-r-1 m-y-0_5 d-f a-i-c">
                      <img src={facebooks} className="w-1_5" />
                      &nbsp;Facebook
                    </div>
                  </a>
                ) : (
                  ""
                )}
                {telegram ? (
                  <a href={telegram} target="_blank">
                    <div className=" bg_g_clr bg_g_clr_btn pa-5px b-r-5 w-s-n-w m-r-1 m-y-0_5 d-f a-i-c">
                      <img src={telegrams} className="w-1_5" />
                      &nbsp;Telegram
                    </div>
                  </a>
                ) : (
                  ""
                )}
                {reddit ? (
                  <a href={reddit} target="_blank">
                    <div className=" bg_g_clr bg_g_clr_btn pa-5px b-r-5 w-s-n-w m-r-1 m-y-0_5 d-f a-i-c">
                      <img src={reddits} className="w-1_5" />
                      &nbsp;Reddit
                    </div>
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-8 col-lg-8 m-y-1">
              <div className="">
                <div className="row j-c-f-e">
                  <div className="col-12 col-sm-12 col-md-3 b-r_1">
                    <div className="m-b-1">
                      <div className="">
                        <div className="f-s-2 green_clr">{price}</div>
                        <div className="">Trust Score</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-3 b-r_1">
                    <div className="m-b-1">24h Volume (Normalised)</div>
                    <div className="m-b-1">
                      {" "}
                      {Market_Cap && btc_price ? (
                        <>
                          ${" "}
                          {(parseFloat(btc_price) * parseFloat(Market_Cap))
                            .toFixed(0)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </>
                      ) : (
                        <Skeleton className="w-5" />
                      )}
                      &nbsp;
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-3">
                    <div className="m-b-1">24h Volume</div>
                    <div className="m-b-1 ">
                      {" "}
                      {volume24h !== null ? (
                        volume24h ? (
                          <>
                            ${" "}
                            {(parseFloat(btc_price) * parseFloat(volume24h))
                              .toFixed(0)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </>
                        ) : (
                          <Skeleton className="w-5" />
                        )
                      ) : (
                        "Untracked Data"
                      )}
                      &nbsp;
                    </div>
                    {/* <div className="m-b-1 green_clr">
                      {" "}
                      <MdArrowDropUp className="b-c-t green_f_clr" />
                      00.09%
                    </div> */}
                    {/* <div className="m-b-1">
                      CEX Vol &nbsp;<BsInfoCircle /> $999,999,999,999{" "}
                    </div>
                    <div className="m-b-1">
                      DEX Vol &nbsp;<BsInfoCircle /> $999,999,999,999{" "}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="container-kws">
          <div className="d-f flex-wrap a-i-c p-y-1">
            <div className="">{description}</div>
          </div>
        </div>
        <hr />
        <div className="container-kws">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-8 m-y-1">
              <Price_Exchange_Chart api={graph_historical_api} />
              <Paper
                sx={{
                  width: "100%",

                  background: "transparent",
                }}
                className="b-s-n m-t-4"
              >
                <TableContainer>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    className="b-c-t "
                  >
                    <TableHead className="b-c-t c-p">
                      <TableRow className="b-c-t c-p ">
                        <TableCell align="left">#</TableCell>
                        <TableCell align="left">Pair</TableCell>
                        <TableCell align="right">Price</TableCell>
                        <TableCell align="right">Spread</TableCell>
                        <TableCell align="right">Volume</TableCell>
                        <TableCell align="right">Trust Score</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ticker &&
                        ticker.map((res, index) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1}>
                              <TableCell align="left">
                                {" "}
                                <a href={res.trade_url} target="_blank">
                                  {index + 1}{" "}
                                </a>
                              </TableCell>
                              <TableCell align="left">
                                <a href={res.trade_url} target="_blank">
                                  <div className="d-f a-i-c">
                                    <div className="m-r-0_5">
                                      <Image_api target_api_id={res.coin_id} />
                                    </div>
                                    <div className="m-r-0_5">{res.base}</div>
                                    <div className="m-r-0_5">/</div>
                                    {res.target_coin_id ? (
                                      <div className="m-r-0_5">
                                        <Image_api
                                          target_api_id={res.target_coin_id}
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <div className="">{res.target}</div>
                                  </div>
                                </a>
                              </TableCell>
                              <TableCell align="right">
                                {" "}
                                <a href={res.trade_url} target="_blank">
                                  {res.last} {res.target}
                                </a>{" "}
                              </TableCell>
                              <TableCell align="right">
                                {" "}
                                <a href={res.trade_url} target="_blank">
                                  {res.bid_ask_spread_percentage} %
                                </a>{" "}
                              </TableCell>
                              <TableCell align="right">
                                {" "}
                                <a href={res.trade_url} target="_blank">
                                  {res.volume} {res.base}
                                </a>{" "}
                              </TableCell>
                              <TableCell align="right">
                                {" "}
                                <a href={res.trade_url} target="_blank">
                                  <AiTwotoneCheckCircle className="f-g-c" />
                                </a>{" "}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {ticker?.length > 0 ? (
                        ""
                      ) : (
                        <>
                          <TableRow hover role="checkbox" tabIndex={-1}>
                            <TableCell align="center">
                              <Skeleton />
                            </TableCell>
                            <TableCell align="center">
                              <Skeleton />
                            </TableCell>
                            <TableCell align="center">
                              <Skeleton />
                            </TableCell>
                            <TableCell align="center">
                              <Skeleton />
                            </TableCell>

                            <TableCell align="center">
                              {" "}
                              <Skeleton />
                            </TableCell>
                            <TableCell align="center">
                              <Skeleton />
                            </TableCell>
                          </TableRow>
                          <TableRow hover role="checkbox" tabIndex={-1}>
                            <TableCell align="center">
                              <Skeleton />
                            </TableCell>
                            <TableCell align="center">
                              <Skeleton />
                            </TableCell>
                            <TableCell align="center">
                              <Skeleton />
                            </TableCell>
                            <TableCell align="center">
                              <Skeleton />
                            </TableCell>

                            <TableCell align="center">
                              {" "}
                              <Skeleton />
                            </TableCell>
                            <TableCell align="center">
                              <Skeleton />
                            </TableCell>
                          </TableRow>
                          <TableRow hover role="checkbox" tabIndex={-1}>
                            <TableCell align="center">
                              <Skeleton />
                            </TableCell>
                            <TableCell align="center">
                              <Skeleton />
                            </TableCell>
                            <TableCell align="center">
                              <Skeleton />
                            </TableCell>
                            <TableCell align="center">
                              <Skeleton />
                            </TableCell>

                            <TableCell align="center">
                              {" "}
                              <Skeleton />
                            </TableCell>
                            <TableCell align="center">
                              <Skeleton />
                            </TableCell>
                          </TableRow>
                          <TableRow hover role="checkbox" tabIndex={-1}>
                            <TableCell align="center">
                              <Skeleton />
                            </TableCell>
                            <TableCell align="center">
                              <Skeleton />
                            </TableCell>
                            <TableCell align="center">
                              <Skeleton />
                            </TableCell>
                            <TableCell align="center">
                              <Skeleton />
                            </TableCell>

                            <TableCell align="center">
                              {" "}
                              <Skeleton />
                            </TableCell>
                            <TableCell align="center">
                              <Skeleton />
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
            <div className="col-12 col-sm-12 col-md-4 m-y-1">
              <div className="b-r-15 bg_violet ">
                <div className="bg_black_clr b-r-5">
                  <Disqus />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-kws"></div>
      </SkeletonTheme>
      <Modal show={show2} onHide={handleClose2} className="">
        <Modal.Body className="pa-0">
          <div className="d-f a-i-c j-c-s-b m-b-1 pa-0">
            <div className="w-1"></div>
            <div>
              {" "}
              <img src={link_logo} className="w-4" />
            </div>
            <div>
              <RxCross2 onClick={handleClose2} className="f-s-1_5 c-p" />
            </div>
          </div>
          <div className="f-s-1_5 f-w-600 t-a-c m-b-1">
            Share it with your friends
          </div>

          <div className="m-b-0_5">or copy link</div>
          <div className="d-f a-i-c j-c-s-b b-1 b-r-10 pa-0_5">
            <input
              type="text"
              value={`${window.location.protocol}//${window.location.hostname}/exchanges/${project_api_name}`}
              className="b-n o-n b-c-t  w-100 m-r-0_5"
            />
            <button
              className=" b-n bg_b_clr pa-5px b-r-5 w-s-n-w c-w"
              onClick={() => {
                const fullUrl = `${window.location.protocol}//${window.location.hostname}/exchanges/${project_api_name}`;
                copyTextToClipboard(fullUrl);
                handleClose2();
              }}
            >
              Copy Link
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Toaster position="top-center" reverseOrder={true} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Footer />
    </>
  );
}

export default Crypto_detail;
